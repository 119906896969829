import React from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import { ArrowButton, Header, Button } from '../../components/base';
import DateTimePicker from '@react-native-community/datetimepicker';
import { DateTime } from "luxon";
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';
import { UtilComponent } from '../../types/UtilComponent';




export interface BirthdayScreenProps extends Navigatable, Route<RegisterFlow> {

}

class BirthdayScreen extends UtilComponent<BirthdayScreenProps> {

    state = {date: this.date, show: Platform.OS == "ios"}
    l_date: DateTime;

    get date(): Date{
        if(!this.l_date){
            if(this.user.birthday){
                this.l_date = DateTime.fromISO(this.user.birthday);
            }
            this.l_date = DateTime.local();
            this.user.birthday = this.l_date.toISO();
        }
        return this.l_date.toJSDate();
    }

    set date(val: Date){
        this.l_date = DateTime.fromJSDate(val);
        this.user.birthday = this.l_date.toISO();
    }



    constructor(props: BirthdayScreenProps) {
        super(props);
    }

    onChange(selectedDate){
        this.date = selectedDate
        this.setState({show: false})
    }

    next(){
        this.props.navigation.navigate("registerEmail", {registerFlow: this.props.route.params.registerFlow })
    }
    
    prev(){
        this.props.navigation.goBack();
    }


    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    
                    <Text style={styles.caption}>Wann hast du Geburtstag?</Text>

                    {Platform.OS == "android" && !this.state.show ?
                        <Button testID="showButton" title={this.date.toLocaleDateString()} onPress={()=>{this.setState({show: true})}} ></Button>
                        :
                        <View/>
                        
                    }
                    
                    {this.state.show || Platform.OS == "ios" ? 
                        <DateTimePicker
                            testID="dateTimePicker"
                            value={this.state.date}
                            mode={'date'}
                            is24Hour={true}
                            display="spinner"
                            
                            onChange={(date)=>{this.onChange(date)}}
                        />
                        : 
                        <View/>
                    }
                    
                    
                    <View style={styles.arrows}>
                        <View style={styles.prev}>
                            <ArrowButton back onPress={()=>{this.prev()}} ></ArrowButton>
                        </View>
                        <View style={styles.next}>
                            <ArrowButton onPress={()=>{this.next()}} ></ArrowButton>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    arrows:{
        flexDirection: "row",
        marginTop: 100
    },
    next:{
        marginLeft: "auto"
    },
    prev:{
    },
    
})



export default BirthdayScreen;