import { transformData } from "data-transformation";
import { HttpService } from "http-service";
import Config from "react-native-config";
import { Singleton } from "singleton-decorator";
import { IAPIInsertion } from "../types/ApiInterfaces/Insertion.get";
import { RentObject } from "../types/dataStructures/RentObject";
import { ViewingRequest } from "../types/dataStructures/ViewingRequest";
import { ApiInsertionToRentObject, RentObjectToApiInsertion } from "../types/mapping";
import { SessionService } from "./Session.Service";

@Singleton
export class ApartmentService extends HttpService{

    sessionService = new SessionService();

    private _newRentObject: RentObject;

    isEditFlow: boolean = false;

    get newRentObject(): RentObject{
        if(!this._newRentObject){
            this._newRentObject = {};
        }
        return this._newRentObject;
    }

    set newRentObject(val: RentObject){
        this._newRentObject = val;
    }

    constructor(){
        super({baseUrl: Config?.API_URL, urlPrefix: "v1/", credentials: "same-origin"})
        this._newRentObject = {}
    }

    
    private _objectList: RentObject[]

    get objectList(): RentObject[]{
        if(!this._objectList){
            this._objectList = [];
            this.updateObjectList();
        }
        return this._objectList;
    }
    
    async updateObjectList(): Promise<RentObject[]>{
        try {
            let data = await this.getMyObjects()
            this._objectList = data;
            return this._objectList
        } catch (error) {
            return error;
        }
    }
    
    getMyObjects(): Promise<Array<RentObject>>{
        return new Promise(async (resolve, reject) => {
            try {
                let apiData: IAPIInsertion[] = await this.get("my/insertions")
                let data: RentObject[] = []
                apiData.forEach(insertion => {
                    data.push(transformData(ApiInsertionToRentObject, insertion));
                });
                resolve(data)
            } catch (error) {
                
            }

        })
    }

    deleteObject(id: string): Promise<boolean>{
        return this.delete("insertion/" + id);
    }

    addObject(object: RentObject): Promise<RentObject>{
        return new Promise(async (resolve, reject) => {
            try {
                let apiObject = transformData(RentObjectToApiInsertion, object);
                console.log(apiObject)
                apiObject = await this.post("insertion", apiObject);
                resolve(transformData(ApiInsertionToRentObject, apiObject));
            } catch (error) {
                reject(error)
            }
        })
    }
    
    getApartment(id: number): RentObject{
        return this.objectList[id]
    }

    getRequests(): Promise<Array<ViewingRequest>>{
        if(this.sessionService.useMocks){
            return new Promise((resolve, reject) =>{
                setTimeout(() => {
                    resolve(undefined)
                }, 80);
            })
        }
        else {
            return this.get("my-viewing-requests");
        }
    }

}