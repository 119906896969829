import { Component } from "react";
import { ApartmentService, ProfileService } from "../services";
import { RentObject, User } from "./dataStructures";

export class UtilComponent <Props> extends Component<Props>{

    protected profileService = new ProfileService();
    protected objectService = new ApartmentService();

    get user(): User{
        return this.profileService.user
    }

    set user(newUser: User){
        this.profileService.user = newUser;
    }


    get objectList(): RentObject[]{
        return this.objectService.objectList;
    }


    constructor(props: Props){
        super(props);
    }

    updateObjectList(): Promise<RentObject[]>{
        return this.objectService.updateObjectList();
    }
    

    setState(newState, callback?: any): Promise<void>{
        return new Promise((resolve) => {
            if(newState.user){
                Object.assign(this.user, newState.user)
                newState.user = this.user;
            }
            let cb = ()=> {
                if(callback)
                    callback()
                resolve()
            }
            super.setState(newState, cb)
        })
    }
}