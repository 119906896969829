import { transformData } from "data-transformation";
import { HttpService } from "http-service";
import Config from "react-native-config";
import { Singleton } from "singleton-decorator";
import { IAPIProfile } from "../types/ApiInterfaces";
import { User } from "../types/dataStructures/UserProfile";
import { UserType } from "../types/dataStructures/UserType";
import { ApiProfiletoUserProfile, UserProfileToApiProfile } from "../types/mapping";
import { StorageService } from "./Storage.Service";


@Singleton
export class ProfileService extends HttpService{
    private _user: User = {userType: UserType.HOUSING_COMPANY}

    private _storage: StorageService


    private get storage(): StorageService{
        !this._storage ? this._storage = new StorageService() : undefined; 
        return this._storage;
    }



    get user(): User{
        if(!this._user){
            this._user = {}
            this.getUser();
        }
        return this._user;
    }
    
    set user(val: User){
        this._user = val;
        try {
            this.storage.setItem("user", JSON.stringify(val))
        } catch (error) {
            console.log("couldn't stringify user");
        }
    }

    
    get userType(): UserType{
        return this.user.userType;
    }

    constructor(){
        super({baseUrl: Config.API_URL , credentials: "include", urlPrefix: "v1/"})
    }

    
    private async getUser(){
        try {
            let userStr = await this.storage.getItem("user");
            this._user = JSON.parse(userStr);
        } catch (error) {
            console.log("couldn't parse user");
        }

    }

    async saveProfile(): Promise<User>{
        let data = transformData(UserProfileToApiProfile, this.user);
        return this.put("my/profil", data)
    }

    register(): Promise<User>{
        let data: IAPIProfile = transformData(UserProfileToApiProfile, this.user);;
        return this.post("signup", data);
    }
    
    private saveUser(profile: IAPIProfile): User{
        this.user = transformData(ApiProfiletoUserProfile, profile);
        return this.user;
    }

    async getMyProfile(): Promise<User>{
        return new Promise(async (resolve, reject) => {
            try {
                let profile: IAPIProfile = await this.get("my/profil");                
                resolve(this.saveUser(profile));
            } catch (error) {
                reject(error)
            }
        })
    }

}
