import { FieldMapping, transformData } from "data-transformation"
import { IAPIInsertion } from "../../../ApiInterfaces";
import { RentObject, RentObjectType } from "../../../dataStructures"
import { ApiProfiletoUserProfile } from "../Profile/ApiProfileToUserProfile.map";

export const ApiInsertionToRentObject: FieldMapping<IAPIInsertion, RentObject> = {
    arePetsAllowed: 'allow_animal',
    allowedPets: (src) => {
        if(src.allow_animal){
            return <any> src.animal_allow_list
        }
    },
    address: 'location',
    constructionYear: 'build_year',
    kaution: 'deposit',
    rent_cold: 'rent_cold',
    rent_complete: 'rent_complete',
    rent_warm: 'rent_warm',
    rooms: 'room_count',
    rent_min: 'rent_min',
    rent_max: 'rent_max',
    type: (src) => src.is_house ? RentObjectType.house : RentObjectType.apartment,
    space: 'area',
    facilities: 'facilities',
    energy: 'energie_value',
    sharing: (src) => [<any>src.flat_sharing],
    provision: 'brokerage',
    id: 'id',
    owner: (src) => transformData(ApiProfiletoUserProfile, src.owner),
    albums: 'albums'
}