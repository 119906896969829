import { FieldMapping, transformData } from "data-transformation"
import { IAPIProfile } from "../../../ApiInterfaces";
import { User, UserType } from "../../../dataStructures"
import { UserCompanyToApiCompany } from "../../sub/company/UserCompanyToApiCompany.map";
import { UserProfileToApiPerson } from "../../sub/person/UserProfileToApiPerson.map";
import { UserProfileToApiPersonLeaser } from "../../sub/personLeaser/UserProfileToApiPersonLeaser.map";

export const UserProfileToApiProfile: FieldMapping<User, IAPIProfile> = {
    company: (src: User) => {
        if(src.company){
            return transformData(UserCompanyToApiCompany, src);
        }
    },
    person: (src: User ) =>{
        if(src.userType != UserType.HOUSING_COMPANY){
            return transformData(UserProfileToApiPerson, src);
        }
    },
    person_leaser: (src: User) => {
        if(src.userType == UserType.TENANT){
            return transformData(UserProfileToApiPersonLeaser, src);
        }
    },
    location: 'address'
}