import React, { Component } from 'react';
import {  StyleSheet } from 'react-native';

import Add from "../../assets/svg/ionicons/add.svg"
import Back from "../../assets/svg/ionicons/chevron-back.svg"
import Forward from "../../assets/svg/ionicons/chevron-forward.svg"
import Close from "../../assets/svg/ionicons/close-outline.svg"
import More from "../../assets/svg/ionicons/ellipsis-vertical-outline.svg"


export interface IconProps {
    size?: number;
    color?: string;
    name?: string;
    style?: any;
    android?: any;
}

class Icon extends Component<IconProps> {

    constructor(props: IconProps) {
        super(props);
    }

    getIcon() { 
        switch(this.props.name) {
            case "more":   return <More style={this.props.style} height={this.props.size} color={this.props.color} ></More>;
            
            case "add":   return <Add style={this.props.style} height={this.props.size} color={this.props.color} ></Add>;
            
            case "ios-arrow-round-back":   return <Back style={this.props.style} height={this.props.size} color={this.props.color} ></Back>;
            case "ios-arrow-back":   return <Back style={this.props.style} height={this.props.size} color={this.props.color} ></Back>;
            
            case "ios-arrow-round-forward":   return <Forward style={this.props.style} height={this.props.size} color={this.props.color} ></Forward>;
            case "ios-arrow-forward":   return <Forward style={this.props.style} height={this.props.size} color={this.props.color} ></Forward>;
            
            case "close-circle-outline":   return <Close style={this.props.style} height={this.props.size} color={this.props.color} ></Close>;
        }
    }

    render() {
        return (
            <>{this.getIcon()}</>
        );
    }
}

const styles = StyleSheet.create({
    
})



export default Icon;