import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Button, ButtonVariants, Header } from '../../components/base';
import { Navigatable, Route } from '../../types/componentInterfaces';
import { RentObject } from '../../types/dataStructures';




export interface FilterPaymentScreenProps extends Navigatable, Route<RentObject> {

}

class FilterPaymentScreen extends Component<FilterPaymentScreenProps> {

    state = { }

    constructor(props: FilterPaymentScreenProps) {
        super(props);
    }

    toPayment(){
        this.props.navigation.navigate("Payment");
    }


    render() {
        return (
            <View style={styles.screen}>
                <Header onBack={this.props.navigation.canGoBack ? this.props.navigation.goBack : undefined} title="Filter bezahlen" ></Header>
                <View style={styles.line} ></View>
                <View style={styles.content}>
                    <Text style={{fontSize: 26, marginBottom: 15}}>Ihr Filter ist kostenpflichtig!</Text>
                    <Text style={{marginBottom: 10}}>Ihr Filter kostet Sie einmalig </Text>
                    <Text style={{fontWeight: "bold"}} >2,50€</Text>
                    <View style={{marginTop: 100}}>
                        <Button onPress={()=>{this.toPayment()}} title="Weiter" variant={ButtonVariants.green} ></Button>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
        paddingTop: 0,
        height: "100%",
        justifyContent: "center",
        
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
   

})



export default FilterPaymentScreen;