import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import Tile from '../../../components/base/Tile';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import { Route } from '../../../types/componentInterfaces/Route';


export interface TenantInterestsTabProps extends Navigatable, Route<Tenant> {

}

class TenantInterestsTab extends Component<TenantInterestsTabProps> {

    state = { }

    constructor(props: TenantInterestsTabProps) {
        super(props);
    }


    renderTiles(){
        if(this.props.route.params.interests){
            return this.props.route.params.interests.map((interest, index) =>(
                <Tile key={index} disabled text={interest}></Tile>
            ))
        }
    }

    render() {
        


        return (
            <View style={styles.screen}>
                {this.renderTiles()}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,   
    },
    screen: {
        backgroundColor: "#ffffff",
        flexDirection: "row",
        flexWrap: "wrap",
        

    },
   

})



export default TenantInterestsTab;