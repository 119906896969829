import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ArrowButton, Header, Textfield } from '../../components/base';
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';

import { isEmpty } from "validator"
import { RegisterFlows } from '../../types/componentInterfaces/RegisterFlow';
import { UtilComponent } from '../../types/UtilComponent';



export interface NameScreenProps extends Navigatable, Route<RegisterFlow> {

}

class NameScreen extends UtilComponent<NameScreenProps> {


    state = {user: this.user, firstnameError:"", lastnameError: ""}


    constructor(props: NameScreenProps) {
        super(props);
    }

    checkInputFirstname(){
        if( !this.user.firstname || isEmpty(this.user.firstname)){
            this.setState({firstnameError: "Pflichtfeld"})
        }
        else{ 
            this.setState({firstnameError: undefined})
        }
    }
    
    checkInputLastname(){
        if( !this.user.lastname || isEmpty(this.user.firstname)){
            this.setState({lastnameError: "Pflichtfeld"})
        } 
        else{
            this.setState({lastnameError: undefined})
        }
    }

    next(){
        if(this.props.route.params.registerFlow == RegisterFlows.TENANT){
            this.props.navigation.navigate("registerBirthday", {registerFlow: this.props.route.params.registerFlow })
        }else{
            this.props.navigation.navigate("registerEmail", {registerFlow: this.props.route.params.registerFlow })
        }
        
    }
    
    prev(){
        this.props.navigation.goBack();
    }

    setFirstname(text: string){
        this.setState({user: {firstname: text}}); 
        if(this.state.firstnameError != undefined)
            this.checkInputFirstname()
    }
    
    setLastname(text: string){
        this.setState({user: {lastname: text}}); 
        if(this.state.lastnameError != undefined)
            this.checkInputLastname()
    }

    getCaption(){
        let caption
        switch (this.props.route.params.registerFlow) {
            case RegisterFlows.HOUSING_COMPANY:
                caption = <Text testID="caption" accessibilityLabel="Ansprechpartner" style={styles.caption}>Ansprechpartner</Text>
            break;
            case RegisterFlows.LANDLORD:
                caption = <Text testID="caption" accessibilityLabel="Wie heißen Sie?" style={styles.caption}>Wie heißen Sie?</Text>
            break;
            case RegisterFlows.TENANT:
                caption = <Text testID="caption" accessibilityLabel="Wie heißt du?" style={styles.caption}>Wie heißt du?</Text>
            break;
        
            default:
                break;
        }
        return caption;
    }

    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    
                    {this.getCaption()}
                    <View style={{marginBottom: 5}}>
                        <Textfield onBlur={()=>{this.checkInputFirstname()}} testID="firstname" accessibilityLabel="firstname" error={this.state.firstnameError} value={this.state.user.firstname} onChange={(text)=>{this.setFirstname(text)}} title="Vorname" ></Textfield>
                    </View>
                    <Textfield onBlur={()=>{this.checkInputLastname()}} testID="lastname" error={this.state.lastnameError} value={this.state.user.lastname} onChange={(text)=>{this.setLastname(text)}} title="Nachname" ></Textfield>
                    
                    <View style={styles.arrows}>
                        <View style={styles.prev}>
                            <ArrowButton back onPress={()=>{this.prev()}} ></ArrowButton>
                        </View>
                        <View style={styles.next}>
                            <ArrowButton testID="next" disabled={this.state.user.firstname == "" || this.state.user.lastname == ""} onPress={()=>{this.next()}} ></ArrowButton>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    arrows:{
        flexDirection: "row",
        marginTop: 100
    },
    next:{
        marginLeft: "auto"
    },
    prev:{
    },
    
})



export default NameScreen;