import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Changeable, Disable, Pressable, Testable } from '../../types/componentInterfaces';
import { UtilComponent } from '../../types/UtilComponent';

export interface NavigationBarItem {
    label?: string,
    args?: any
}

export interface NavigationBarProps extends Disable, Testable, Changeable<NavigationBarItem>, Testable {

    items?: NavigationBarItem[]

    value: NavigationBarItem
}

class NavigationBar extends UtilComponent<NavigationBarProps> {

    itemPositions: {label: string, x: number} [] = [] 
    state = { value: this.props.value, itemsPosition: this.itemPositions }


    scrollView: ScrollView

    constructor(props: NavigationBarProps) {
        super(props);
        
        
    }
    
    change(item: NavigationBarItem){
        this.setState( {value: item});
        if(this.props.onChange){
            this.props.onChange(item);
        }
    }

    componentDidUpdate(prevProps: NavigationBarProps){
        if(prevProps.value != this.props.value){
            let itemPosition = this.state.itemsPosition.find(i => i.label == this.props.value.label);
            if(itemPosition){
                this.scrollView.scrollTo({x: Math.max(itemPosition.x - 5, 0)})
            }
        }
    }

    render() {
        
        return (
            <View style={styles.wrapper}>
                <ScrollView ref={(ref: ScrollView) => {this.scrollView =  ref}} horizontal>
                    <View style={styles.scrollContainer}>
                        {this.props.items?.map((item, index) => (
                            <TouchableOpacity onLayout={(event) => {this.itemPositions.push({label: item.label, x:event.nativeEvent.layout.x})}} onPress={() => { this.change(item) }} key={index} style={[styles.button, this.props.value.label == item.label ? styles.selected : undefined]}>
                                <Text style={[styles.text, this.props.value.label == item.label ? styles.selectedText : undefined]}>{item.label}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#ffffff',
        shadowColor: '#000000',
        elevation: 5,
        height: 45,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        overflow: 'hidden'

    },
    scrollContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 10,
        paddingRight: 10,
    },
    button: {
        backgroundColor: '#ffffff',
        flex: 0,
        alignSelf: 'flex-start',
        margin: 5,
        padding: 7,
        borderRadius: 5,
        paddingRight: 25,
        paddingLeft: 25,
        
    },
    selected:{
        backgroundColor: '#274293',

    },
    text: {
        color: '#A6BCD0',
    },
    selectedText:{
        color: '#ffffff',
    }


})


export default NavigationBar;

