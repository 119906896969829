import React, { Component } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Disable, Pressable } from '../../types/componentInterfaces';
import Icon  from './Icon';



export interface GridButtonProps extends  Disable, Pressable {
    title?: string,
    subTitle?: string,
    icon?: string
}

class GridButton extends Component<GridButtonProps> {

    constructor(props: GridButtonProps) {
        super(props);
        
    }

    render() {
        return (
            <TouchableOpacity onPress={()=>{this.props.onPress ? this.props.onPress() : undefined}}>
                <View style={gridStyles.common}>
                    <View >
                        {this.props.children ? 
                            this.props.children
                        :   
                            <Icon style={gridStyles.title} android="ios-arrow-round-back"></Icon>
                        }
                        <Text style={gridStyles.title}>{this.props.title}</Text>
                        <Text style={gridStyles.subTitle}>{this.props.subTitle}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

const gridStyles = StyleSheet.create({
    common:{
        height: 150, 
        width: 150,
        backgroundColor: "#F0F4F8",
        alignItems: "center",
        justifyContent: 'center',
        borderRadius: 8,
        padding: 5
    },
    title:{
        color: "#748A9D",
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: 16
    },
    subTitle:{
        color: "#A6BCD0",
        textAlign: "center",
        fontSize: 16
    }
})



export default GridButton;