import React, { Component } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { View, StyleSheet, ScrollView, RefreshControl,  } from 'react-native';
import { Header } from '../../components/base';
import User from "../../assets/svg/User.svg";
import { DateTime } from 'luxon';
import { ApartmentService } from '../../services';
import { Navigatable } from '../../types/componentInterfaces';
import { ViewingRequest, RentObject } from '../../types/dataStructures';

export interface ViewingRequestScreenProps extends Navigatable {

}

class ViewingRequestScreen extends Component<ViewingRequestScreenProps> {

    requests: Array<ViewingRequest> = new Array()

    state = {refreshing: false, requests: this.requests}

    apartmentService = new ApartmentService();

    constructor(props: ViewingRequestScreenProps) {
        super(props);

    }

    componentDidMount() {
        this.onRefresh();
    }

    toApartment(apartment: RentObject){
        this.props.navigation.navigate("ApartmentDetail", apartment)
    }

    renderList(){
        return this.state.requests.map((request, index) =>{
            let date: DateTime = DateTime.fromISO(request.date); 
            return (
            <TouchableOpacity  key={index}>
                <View style={styles.listElement}>
                    <View style={styles.row}>
                        <User></User>
                        <View style={{padding: 10}}>
                            <Text style={{fontWeight: "bold", marginBottom: 5}}>{request.tenant.firstname} {request.tenant.lastname}, {request.tenant.profession}</Text>
                            <Text style={{marginBottom: 15}}>Sucht: {request.tenant.desiredRooms} Zimmer, Budget: {request.tenant.budgetMin} - {request.tenant.budgetMax}</Text>
                            <Text style={{marginBottom: 5}}>Erwartete Austattung:</Text>
                            <Text>
                                {request.tenant.desiredFeatures.map((feature, index, array )=>(
                                    <Text style={{fontSize: 12, color: "#767984"}} key={index}>{feature}{index == array.length -1 ? "" : " | "}</Text>
                                ))}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.date}>
                        <Text>{request.apartment.address.street}, {request.apartment.address.zip_code} {request.apartment.address.city}</Text>
                        <View style={[styles.row, styles.spaceBetween, {marginTop: 20}]}>
                            <View>
                                <Text>Tag</Text>
                                <Text>{date.weekdayLong}</Text>
                                <View style={[styles.line, {width: 80}]} ></View>
                            </View>
                            <View>
                                <Text>Tag</Text>
                                <Text>{date.toFormat("dd.MM.yyyy")}</Text>
                                <View style={[styles.line, {width: 80}]} ></View>
                            </View>
                            <View>
                                <Text>Tag</Text>
                                <Text>{date.toFormat("hh:mm")} Uhr</Text>
                                <View style={[styles.line, {width: 80}]} ></View>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        )})
    }
    
    async onRefresh(){
        this.setState({refreshing: true})
        try {
            let requests = await this.apartmentService.getRequests();
            this.setState({ requests: requests })
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({refreshing: false})

        }
    }

    render() {
        return (
            // <SafeAreaView >
                <View style={styles.screen}>
                    <Header onBack={this.props.navigation.canGoBack ? this.props.navigation.goBack : undefined } title="Wohnungsbesichtigung" onMenu={()=>{console.log("ARRRGH")}}></Header>
                    <View style={styles.line} ></View>
                    <View  >
                        <ScrollView refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={()=>{this.onRefresh()}}/>} >
                            {this.renderList()}
                        </ScrollView>
                    </View>
                </View>
            // </SafeAreaView>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen:{
        backgroundColor: "#ffffff",
        height: "100%",
        
    },
    bold:{
        fontWeight: "bold"
    },
    caption:{
        fontSize: 30,
        marginBottom: 20,
        marginRight: "auto"
    },
    listElement: {
        borderColor: "#27429372",
        borderWidth: 1,
        borderStyle: 'solid',
        margin: 13,
    },
    row:{
        flexDirection: "row"
    },
    spaceBetween:{
        justifyContent: "space-between"
    },
    date:{
        borderTopColor: "#27429372",
        borderTopWidth: 1,
        borderStyle: "solid",
        padding: 10
    },
    thumbnail:{
        width: "30%",
        height: 100
    },
    picture:{
        width: "100%",
        height: "100%"
    },
    text:{
        padding: 8
    }
    
})



export default ViewingRequestScreen;