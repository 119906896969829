import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Header, Button, ButtonVariants } from '../../components/base';
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';
import { RegisterFlows } from '../../types/componentInterfaces/RegisterFlow';
import { UtilComponent } from '../../types/UtilComponent';

export interface FinishScreenProps extends Navigatable, Route<RegisterFlow> {

}

class FinishScreen extends UtilComponent<FinishScreenProps> {

    state = {Finish: "", FinishRepeat: "", error: "", repeatError: ""}

    constructor(props: FinishScreenProps) {
        super(props);
    }

    toLogin(){
        this.props.navigation.replace("Login")
    }

    openMails(){
        
    }

    getCaption(){
        let caption
        switch (this.props.route.params.registerFlow) {
            case RegisterFlows.HOUSING_COMPANY:
                caption = <Text style={styles.caption}>Ihre Registrierung war erfolgreich</Text>
            break;
            case RegisterFlows.LANDLORD:
                caption = <Text style={styles.caption}>Ihre Registrierung war erfolgreich</Text>
            break;
            case RegisterFlows.TENANT:
                caption = <Text style={styles.caption}>Deine Registrierung war erfolgreich</Text> 
            break;
        
            default:
                break;
        }
        return caption;
    }

    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" ></Header>
                </View>
                <View style={styles.content}>
                    <Text style={styles.icon} >🥳</Text>
                    {this.getCaption()}
                    
                    <Text style={styles.text}>
                    Jetzt musst du nur noch deine 
                    Registrierung bestätigen. 
                    Wir haben dir an deine E-mail-Adresse 
                    eine Link Zu Bestätigung gesendet.   
                    </Text>
                    <View style={styles.mail}>
                        <Button title="E-Mails öffnen" variant={ButtonVariants.text} onPress={()=>{this.openMails()}}></Button>
                    </View>
                    <Button title="Login" variant={ButtonVariants.primary} onPress={()=>{this.toLogin()}}></Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    icon:{
        fontSize: 40,
        textAlign: "center",
        marginBottom: 10
    },
    text:{
        marginBottom: 40
    },
    mail:{
        marginBottom: 10
    }


})



export default FinishScreen;