import { HttpService } from "http-service";
import { Singleton } from "singleton-decorator";
import Config from "react-native-config"

import { ListData } from "../types/dataStructures/ListData";

@Singleton
export class ItemService extends HttpService {

    constructor(){
        super({baseUrl: Config.API_URL, urlPrefix: "v1/", credentials: "same-origin"})        
    }

    async getCities(): Promise<Array<ListData>>{
        return this.get("cities");
    }

    async getItems(endpoint: string): Promise<Array<ListData>>{
        return this.get(endpoint);
    }

}
