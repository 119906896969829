import { Singleton } from "singleton-decorator";
import SInfo from 'react-native-sensitive-info';
import AsyncStorage from "@react-native-community/async-storage"

@Singleton
export class StorageService {

    secureSetItem(key: string, value: string): Promise<null>{
        return SInfo.setItem(key, value, {sharedPreferencesName: "Meine Bude", keychainService: "Meine Bude"})
    }
    
    secureGetItem(key: string): Promise<string>{
        return SInfo.getItem(key, {sharedPreferencesName: "Meine Bude", keychainService: "Meine Bude"})
    }
    
    setItem(key: string, value: string): Promise<void>{
        return AsyncStorage.setItem(key, value);
    }
    
    getItem(key: string): Promise<string>{
        return AsyncStorage.getItem(key);
    }

    removeItem(key: string): Promise<any>{
        return AsyncStorage.removeItem(key);
    }

}
