import { FieldMapping } from "data-transformation"
import { PersonLeaser, User } from "../../../dataStructures"

export const UserProfileToApiPersonLeaser: FieldMapping<User, PersonLeaser> = {
    bail: (src: User) => src.tenantDetails.bail,
    birthday: (src: User) => src.birthday,
    company: (src: User) => src.company.name,
    company_city_id: (src: User) => src.company.company_city.id,
    eidesstaatliche_versicherung_3years: (src: User) => src.tenantDetails.eidesstattliche_versicherung_3years,
    haftpflicht: (src: User) => src.tenantDetails.haftpflicht,
    interests: (src: User) => src.tenantDetails.interests,
    job_art_id: (src: User) => src.tenantDetails.job_art.id,
    job_id: (src: User) => src.tenantDetails.job.id,
    job_sector_id: (src: User) => src.tenantDetails.job_sector.id,
    job_contract_till: (src: User) => src.tenantDetails.job_contract_till,
    konkurs_insolvenz_5years: (src: User) => src.tenantDetails.konkurs_insolvenz_5years,
    salary_accountings: (src: User) => src.tenantDetails.salary_accountings,
    salary_without_tax: (src: User) => src.tenantDetails.salary_without_tax,
    schufa: (src: User) => src.tenantDetails.schufa,
    wohnberechtigungsschein: (src: User) => src.tenantDetails.wohnberechtigungsschein,
}

