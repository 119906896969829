import { FieldMapping, transformData } from "data-transformation"
import { IAPIProfile } from "../../../ApiInterfaces"
import { User, UserType } from "../../../dataStructures"
import { ApiCompanyToUserCompany } from "../../sub/company/ApiCompanyToUserCompany.map";

export const ApiProfiletoUserProfile: FieldMapping<IAPIProfile, User> = {
    id: 'id',
    company: (src: IAPIProfile) => {
        if(src.company){
            return transformData(ApiCompanyToUserCompany, src.company);
        }
    },
    email: 'username',
    birthday: (src: IAPIProfile) => {
        if(src.person_leaser)
            return src.person_leaser.birthday
    },
    introduction: (src: IAPIProfile) => {
        if(src.person)
            return src.person.introduction
    }, 
    tenantDetails: (src: IAPIProfile) => {
        if(src.person_leaser)
            return src.person_leaser
    },
    phone_number: (src: IAPIProfile) => {
        if(src.person)
            return src.person.phone_number
    },
    personal_status: (src: IAPIProfile) => {
        if(src.person_leaser)
            return src.person.personal_status
    },
    address: 'location',
    userType: (src: IAPIProfile) => {
        if(src.is_leaser == true)
            return UserType.TENANT
        else if(src.company)
            return UserType.HOUSING_COMPANY
        else
            return UserType.LANDLORD
    },
    firstname: (src: IAPIProfile) => {
        if(src.company && src.company.contact)
            return src.company.contact.firstname
        else
            return src.person.firstname
        },
    lastname: (src: IAPIProfile) => {
        if(src.company && src.company.contact)
            return src.company.contact.surname
        else
            return src.person.surname
    },
    title: (src: IAPIProfile) => {
        if(src.company && src.company.contact)
            return src.company.contact.title
        else
            return src.person.title
    },

}