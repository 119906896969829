

import React, { Component, } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { withAnchorPoint } from 'react-native-anchor-point';

export interface ProgressCircleProps {
    color: string,
    bgcolor: string,
    innerColor: string,
    radius: number,
    percent: number,
    borderWidth: number,
    disabled: boolean,
    scaleWidth: number
}

class ProgressCircle extends Component<ProgressCircleProps> {

    state = { borderWidth: this.props.borderWidth }

    constructor(props: ProgressCircleProps) {
        super(props);

        this.state = {
            borderWidth: this.props.borderWidth < 2 || !this.props.borderWidth ? 2 : this.props.borderWidth,
        };
    }

    getTransform = (rotation) => {
        let transform = {
            transform: [{ perspective: 400 }, { rotate: rotation }],
        };
        return withAnchorPoint(transform, { x: 0.5, y: 0.5 }, { width: this.props.borderWidth, height: this.props.radius * 2 });
    };

    getLineTransform = (rotation) => {
        let transform = {
            transform: [{ perspective: 400 }, { rotate: rotation }],
        };
        return withAnchorPoint(transform, { x: 0.5, y: 0.5 }, { width: this.props.scaleWidth, height: this.props.radius * 2 + 20});
    };

    render() {

        let percent = this.props.percent <= 100 ? this.props.percent : 100 ;
        let cursorPosition = (360 * percent/100)+"deg" ;
        let leftTransformerDegree = '0deg';
        let rightTransformerDegree = '0deg';
        if (percent >= 50) {
            rightTransformerDegree = '180deg';
            leftTransformerDegree = (percent - 50) * 3.6 + 'deg';
        } else {
            rightTransformerDegree = percent * 3.6 + 'deg';
        }

        if (this.props.disabled) {
            return (
                <View style={[styles.circle, {
                    width: this.props.radius * 2,
                    height: this.props.radius * 2,
                    borderRadius: this.props.radius
                }]}>
                    <Text style={styles.text}></Text>
                </View>
            );
        }
        return (
            <View style={{margin: 20}}>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("22.5deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("45deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("67.5deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("90deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("112.5deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("135deg")]} ></View>
                <View style={[{position: "absolute", left: this.props.radius - this.props.scaleWidth / 2 , top: -10,  height: this.props.radius * 2 + 20, width: 2.5, backgroundColor: this.props.color}, this.getLineTransform("157.5deg")]} ></View>

                <View style={{position: "absolute", left: this.props.radius - 4, top: 0,  height: this.props.radius * 2, width: this.props.borderWidth, zIndex: 999}} >
                    <View style={[{backgroundColor: "#ffffff", width: this.props.borderWidth, height: this.props.borderWidth, borderRadius: this.props.borderWidth, marginTop: 0 , justifyContent: "center", alignItems: "center"}, styles.shadow]}>
                        <View style={{height: this.props.borderWidth * 0.4, width: this.props.borderWidth * 0.4, borderRadius: this.props.borderWidth * 0.4, backgroundColor: "#574A43"}} ></View>
                    </View>
                </View>
                <View style={[{position: "absolute", left: this.props.radius - (this.props.borderWidth / 2) , top: 0,  height: this.props.radius * 2, width: this.props.borderWidth, zIndex: 999}, this.getTransform(cursorPosition)]} >
                    <View style={[{backgroundColor: "#ffffff", width: this.props.borderWidth, height: this.props.borderWidth, borderRadius: this.props.borderWidth, marginTop: 0 , justifyContent: "center", alignItems: "center"}, styles.shadow]}>
                        <View style={{height: this.props.borderWidth * 0.4, width: this.props.borderWidth * 0.4, borderRadius: this.props.borderWidth * 0.4, backgroundColor: "#574A43"}} ></View>
                    </View>
                </View>
                <View style={[styles.circle, {
                    width: this.props.radius * 2,
                    height: this.props.radius * 2,
                    borderRadius: this.props.radius,
                    backgroundColor: this.props.bgcolor
                }]}>
                    <View style={[styles.leftWrap, {
                        width: this.props.radius,
                        height: this.props.radius * 2,
                        left: 0,
                    }]}>
                        <View style={[styles.loader, {
                            left: this.props.radius,
                            width: this.props.radius,
                            height: this.props.radius * 2,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            backgroundColor: this.props.color,
                            transform: [{ translateX: -this.props.radius / 2 }, { rotate: leftTransformerDegree }, { translateX: this.props.radius / 2 }],
                        }]}></View>
                    </View>
                    <View style={[styles.leftWrap, {
                        left: this.props.radius,
                        width: this.props.radius,
                        height: this.props.radius * 2,
                    }]}>
                        <View style={[styles.loader, {
                            left: -this.props.radius,
                            width: this.props.radius,
                            height: this.props.radius * 2,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            backgroundColor: this.props.color,
                            transform: [{ translateX: this.props.radius / 2 }, { rotate: rightTransformerDegree }, { translateX: -this.props.radius / 2 }],
                        }]}></View>
                    </View>
                    <View style={[styles.innerCircle, {
                        width: (this.props.radius - this.state.borderWidth) * 2,
                        height: (this.props.radius - this.state.borderWidth) * 2,
                        borderRadius: this.props.radius - this.state.borderWidth,
                        backgroundColor: this.props.innerColor,
                    }]}>
                        {this.props.children ? this.props.children :
                            <Text style={[styles.text]}>{this.props.percent}%</Text>}
                    </View>

                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    circle: {
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e3e3e3',
    },
    shadow:{
        shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 1,
},
shadowOpacity: 0.22,
shadowRadius: 2.22,

elevation: 3,
    },
    leftWrap: {
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
    },
    rightWrap: {
        position: 'absolute',

    },

    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: 1000,

    },

    innerCircle: {
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 11,
        color: '#888',
    },
});



export default ProgressCircle;