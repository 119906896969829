import { HttpService, KnownError } from "http-service";
import { Singleton } from "singleton-decorator";
import Config from "react-native-config"
import { AuthStatus } from "../types/dataStructures/AuthStatus";
import { SessionService } from "./Session.Service";

const knownErrors: Array<KnownError> = [
    {error: 'user not found', message: "Benutzer nicht gefunden..."},
    {error: 'incorrect password', message: "Passwort falsch"}
]

@Singleton
export class AuthService extends HttpService {

    authCallback: ()=> void;

    constructor(){
        super({baseUrl: Config.API_URL, urlPrefix: "v1/", credentials: "same-origin", knownErrors})        
    }

    status(): Promise<AuthStatus>{
        return this.get("auth/status");
    }

    login(username: string, password: string): Promise<AuthStatus>{
        return this.post("auth/login", {username, password})
    }

    logout(): Promise<boolean>{
        if(new SessionService().useMocks){
            return new Promise((resolve, reject) => {
                resolve(true);
            })
        }
        return this.post("auth/logout", {});
    }
}
