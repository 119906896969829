export enum ItemTypes{
    CITIES="cities",
    CORPORATE_FORMS="corporate-forms",
    INTERESTS="interests",
    JOB_ARTS="job-arts",
    JOBS="jobs",
    PERSONAL_STATUS="personal-status",
    SECTORS="sectors",
    FACILITIES="facilities",
    PETS="animals",
    SHARING="flat-sharings"
}