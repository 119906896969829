import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Header, Button, ArrowButton, ButtonVariants, Textfield } from '../../components/base';
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';

import { isEmpty, isStrongPassword, equals } from "validator";
import { RegisterFlows } from '../../types/componentInterfaces/RegisterFlow';
import { UtilComponent } from '../../types/UtilComponent';

export interface PasswordScreenProps extends Navigatable, Route<RegisterFlow> {

}

class PasswordScreen extends UtilComponent<PasswordScreenProps> {

    state = {password: "", passwordRepeat: "", error: undefined, repeatError: undefined}

    constructor(props: PasswordScreenProps) {
        super(props);
        
    }

    register(){
        try {
            let res = this.profileService.register();
            if(res){
                this.props.navigation.reset({index: 0, routes: [{name: "registerFinish", params: {registerFlow: this.props.route.params.registerFlow }}]})
            }
            
        } catch (error) {
            
        }
    }

    checkInputs(): Promise<void>{
        return new Promise<void> ( async (resolve) => {
            let error1;
            let error2;
            
            if(isEmpty(this.state.password)){
                error1 = "Bitte gebe ein Passwort ein. Das Passwort sollte mindestens 8 Zeichen lang sein, Groß- und Kleinbuchstaben, sowie Zahlen und Sonderzeichen enthalten."
                await this.setState({error: error1})
            }
            else if(!isStrongPassword(this.state.password)){
                error1 = "Das Passwort sollte mindestens 8 Zeichen lang sein, Groß- und Kleinbuchstaben, sowie Zahlen und Sonderzeichen enthalten."
                await this.setState({error: error1})
            }
            else{
                error1 = undefined;
                await this.setState({error: undefined})
            }
    
            if(!equals(this.state.password, this.state.passwordRepeat )){
                error2 = "Die Passwörter stimmen nicht überein.";
                await this.setState({repeatError: error2})
            }
            else{
                error2 = undefined;
                await this.setState({repeatError: undefined})
    
            }
            resolve()
        })

    }
    
    prev(){
        this.props.navigation.goBack();
    }

    getCaption(){
        let caption
        switch (this.props.route.params.registerFlow) {
            case RegisterFlows.HOUSING_COMPANY:
                caption = <Text testID="caption" style={styles.caption}>Wie soll Ihr Passwort lauten?</Text>
            break;
            case RegisterFlows.LANDLORD:
                caption = <Text testID="caption" style={styles.caption}>Wie soll Ihr Passwort lauten?</Text>
            break;
            case RegisterFlows.TENANT:
                caption = <Text testID="caption" style={styles.caption}>Wie soll dein Passwort lauten?</Text> 
            break;
        
            default:
                break;
        }
        return caption;
    }

    async setPassword(text: string) {
        this.setState({password: text}, this.checkInputs.bind(this));
    }

    async setPasswordRepeat(text: string) {
        this.setState({passwordRepeat: text}, this.checkInputs.bind(this));
    }

    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    
                    {this.getCaption()}
                    
                    <Textfield testID="password" longHint="Das Passwort sollte mindestens 8 Zeichen lang sein, Groß- und Kleinbuchstaben, sowie Zahlen und Sonderzeichen enthalten." error={this.state.error} secureTextEntry value={this.state.password} onChange={(text)=>{this.setPassword(text)}} title="Passwort" ></Textfield>
                    <Textfield testID="passwordRepeat" error={this.state.repeatError} secureTextEntry value={this.state.passwordRepeat} onChange={(text)=>{this.setPasswordRepeat(text)}} title="Passwort wiederholen" ></Textfield>
                    
                    <View style={styles.arrows}>
                        <View style={styles.prev}>
                            <ArrowButton back onPress={()=>{this.prev()}} ></ArrowButton>
                        </View>
                        {/* <View style={styles.next}>
                            <ArrowButton onPress={()=>{this.next()}} ></ArrowButton>
                        </View> */}
                    </View>
                    <Button testID="register" disabled={!(this.state.error == undefined && this.state.repeatError == undefined)} title="Registrieren" variant={ButtonVariants.primary} onPress={()=>{this.register()}}></Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    arrows:{
        flexDirection: "row",
        marginTop: 100,
        marginBottom: 30
    },
    next:{
        marginLeft: "auto"
    },
    prev:{
    },


})



export default PasswordScreen;