import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import { Route } from '../../../types/componentInterfaces/Route';


export interface TenantPersonalTabProps extends Navigatable, Route<Tenant> {

}

class TenantPersonalTab extends Component<TenantPersonalTabProps> {

    state = { }

    constructor(props: TenantPersonalTabProps) {
        super(props);
    }


    render() {
        let tenant = this.props.route.params;
        let birthday = DateTime.fromISO(tenant.birthday).toLocaleString()
        return (
            <View style={styles.screen}>
                <View style={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Anrede</Text>
                        <Text style={styles.text}>{tenant.title}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Name</Text>
                        <Text style={styles.text}>{tenant.firstname} {tenant.lastname}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Geburtsdatum</Text>
                        <Text style={styles.text}>{birthday}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Familienstand</Text>
                        <Text style={styles.text}>{tenant.family}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Einziehende Personen</Text>
                        <Text style={styles.text}>{tenant.roomMates}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Einziehende Kinder</Text>
                        <Text style={styles.text}>{tenant.kids}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Haustiere</Text>
                        <Text style={styles.text}>{tenant.pets ? tenant.pets.map(pet =>(pet + " " )) : "keine"}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.caption}>Raucher</Text>
                        <Text style={styles.text}>{tenant.smoker ? "ja" : "nein"} {tenant.smoker ? (tenant.smokeInside ? "Auch in der Wohnung" : "Aber nicht in der Wohnung") : ""}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
        height: "100%",        
    },
    pictures: {
        width: "100%",
        height: 200
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption:{
        fontSize: 10,
        color: "#A2A8B6"
    },
    text:{
        marginTop: 5,
        marginBottom: 5
    },
    section:{
        marginBottom: 20
    }
   

})



export default TenantPersonalTab;