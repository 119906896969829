import React, { Component } from 'react';
import { Text, StyleSheet, Animated, View } from 'react-native';

export interface AnimatedLineProps {
  isError?: boolean;
}

export default class AnimatedLine extends Component<AnimatedLineProps> {

  state = { backgroundColor: new Animated.Value(0), height: new Animated.Value(0) }

  constructor(props: AnimatedLineProps) {
    super(props);
  }

  onFocus() {
    this.setState({ backgroundColor: new Animated.Value(0) }, () => {
      Animated.timing(this.state.backgroundColor, {
        toValue: 100,
        duration: 100,
        useNativeDriver: false,

      }).start();
    });

    this.setState({ height: new Animated.Value(0) }, () => {
      Animated.timing(this.state.height, {
        toValue: 100,
        duration: 100,
        useNativeDriver: false,

      }).start();
    });
  }

  onBlur() {
    this.setState({ backgroundColor: new Animated.Value(1) }, () => {
      Animated.timing(this.state.backgroundColor, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false,

      }).start();
    });

    this.setState({ height: new Animated.Value(0) }, () => {
      Animated.timing(this.state.height, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false,

      }).start();
    });
  }


  render() {
    return (
      <View style={{height: 2}}>
        <Animated.View
          style={[
            {
              backgroundColor: this.state.backgroundColor.interpolate({
                inputRange: [0, 100],
                outputRange: [this.props.isError ? "red" : "#707070", this.props.isError ? "red" : "#4892F1"]
              })
            },
            {
              height: this.state.height.interpolate({
                inputRange: [0, 100],
                outputRange: [1, 2]
              })
            },
          ]}
        >
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({

});