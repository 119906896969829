import { FieldMapping, transformData } from "data-transformation"
import { IAPIInsertion } from "../../../ApiInterfaces";
import { RentObject, RentObjectType } from "../../../dataStructures"
import { LocationToApiLocation } from "../../sub/Location/LocationToApiLocation.map";

export const RentObjectToApiInsertion: FieldMapping<RentObject, IAPIInsertion> = {
    albums: 'albums',
    allow_animal: 'arePetsAllowed',
    animal_allow_list: (src) => src.arePetsAllowed ? src.allowedPets.map(pet => pet.id) : undefined,
    area: src => Number(src.space),
    brokerage: src => Number(src.provision),
    build_year: src => Number(src.constructionYear),
    deposit: src => Number(src.kaution),
    energie_value: src => Number(src.energy),
    facilities: (src) => src.facilities ? src.facilities.map(fac => fac.id) : undefined,
    flat_sharing: (src) => src.sharing ? <any>src.sharing[0].id : undefined,
    is_house: (src) => src.type == RentObjectType.house,
    location: src => transformData(LocationToApiLocation, src.address),
    rent_cold: src => Number(src.rent_cold),
    rent_max: src =>  Number(src.rent_max),
    rent_min: src =>  Number(src.rent_min),
    rent_warm: src =>  Number(src.rent_warm),
    rent_complete: src =>  Number(src.rent_complete),
    room_count: src =>  Number(src.rooms),
}