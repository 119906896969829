import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { NavigationBar, NavigationBarItem } from '../../../components/base';
import Header from '../../../components/base/Header';
import { Route } from '../../../types/componentInterfaces';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import ApartmentNewTypeScreen from './Apartment.New.Type';
import ApartmentNewCostsScreen from './Apartment.New.Costs';
import ApartmentNewFeaturesScreen from './Apartment.New.Features';
import ApartmentNewLocationScreen from './Apartment.New.Location';
import ApartmentNewPetsScreen from './Apartment.New.Pets';
import ApartmentNewSharingScreen from './Apartment.New.Sharing';
import ApartmentNewSizeScreen from './Apartment.New.Size';
import ApartmentNewSpecificTypeScreen from './Apartment.New.SpecificType';
import ApartmentNewPictruesScreen from './Apartment.New.Pictures';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';


const Stack = createStackNavigator();

export interface NewApartmentBaseScreenProps extends Navigatable, Route<any> {

}

class NewApartmentBaseScreen extends ApartmentUtilComponent<NewApartmentBaseScreenProps> {

    state = {current:{}}

    items: Array<NavigationBarItem> = [
        {label: "Was", args: 'Type'},
        {label: "Typ", args: 'SpecificType'},
        {label: "Wo", args: 'Location'},
        {label: "Fläche", args: 'Size'},
        {label: "Kosten", args: 'Costs'},
        {label: "Ausstatung", args: 'Features'},
        {label: "Mieter", args: 'Tenants'},
        {label: "Haustiere", args: 'Pets'},
        {label: "Bilder hinzufügen", args: 'Pictures'},
    ]

    constructor(props: NewApartmentBaseScreenProps) {
        super(props);
        
    }

    back() {
        
        this.props.navigation.goBack()
    }

    navigate(route: NavigationBarItem){
        this.props.navigation.navigate(route.args)
    }

    setCurrent(route: string){
        let index = this.items.findIndex( i => (i.args == route));
        this.setState({current: this.items[index]});
    }
    

    render() {
        return (
            <View style={styles.common}>
                <Header onBack={()=>{this.back()}} title={this.apartmentService.isEditFlow ? "Inserat bearbeiten" : "Dein neues Inserat"} ></Header>
                <View style={styles.line} ></View>
                <NavigationBar items={this.items} value={this.state.current} onChange={(item)=> {this.navigate(item)}} ></NavigationBar>

                <Stack.Navigator>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Type') }})} options={{headerShown: false}} name="Type" component={ApartmentNewTypeScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('SpecificType') }})} options={{headerShown: false}} name="SpecificType" component={ApartmentNewSpecificTypeScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Location') }})} options={{headerShown: false}} name="Location" component={ApartmentNewLocationScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Size') }})} options={{headerShown: false}} name="Size" component={ApartmentNewSizeScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Costs') }})} options={{headerShown: false}} name="Costs" component={ApartmentNewCostsScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Features') }})} options={{headerShown: false}} name="Features" component={ApartmentNewFeaturesScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Pets') }})} options={{headerShown: false}} name="Pets" component={ApartmentNewPetsScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Tenants') }})} options={{headerShown: false}} name="Tenants" component={ApartmentNewSharingScreen} ></Stack.Screen>
                    <Stack.Screen listeners={() => ({focus: e => { this.setCurrent('Pictures') }})} options={{headerShown: false}} name="Pictures" component={ApartmentNewPictruesScreen} ></Stack.Screen>
                </Stack.Navigator>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        height: "100%",
        width: "100%",
        backgroundColor: '#ffffff'
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    
})



export default NewApartmentBaseScreen;