import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Menu from "../../assets/svg/Menu.svg";
import ArrowButton from './ArrowButton';


export interface HeaderProps{
    title?: string;
    icon?: string;
    onBack?: ()=> void;
    onMenu?: ()=> void;

}

class Header extends Component<HeaderProps> {

    constructor(props: HeaderProps) {
        super(props);
        
    }

    render() {
        return (
            <View style={styles.common}>
                <View style={styles.back}>
                    { this.props.onBack ? 
                        <ArrowButton onPress={()=>{this.props.onBack()}} color="#4892F1" chevron back></ArrowButton>
                        :
                        <View/>
                    }
                </View>
                <Text style={styles.title}>{this.props.title}</Text>
                <View style={styles.menu}>
                    { this.props.onMenu ?
                        <TouchableOpacity onPress={()=>{this.props.onMenu()}}>
                            <Menu></Menu>
                        </TouchableOpacity>
                    :
                        this.props.children ?
                            this.props.children
                        :
                            <></>
                    }
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        height: 70,
        alignItems: "center",
        //justifyContent: "center",
        flexDirection: "row"

    },
    title:{
        fontSize: 16,
        flexGrow: 1,
        textAlign: "center"
    },
    back:{
        marginLeft: 20
    },
    menu:{
        marginRight: 20
    }
    
})



export default Header;