import React, { Component, useState } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import Button, { ButtonVariants } from '../../../components/base/Button';
import Textfield from '../../../components/base/Textfield';
import { UserType } from '../../../types/dataStructures/UserType';
import { UtilComponent } from '../../../types/UtilComponent';


export interface IntroTextScreenProps {

}

class IntroTextScreen extends UtilComponent<IntroTextScreenProps> {

    state = {user: this.user}

    

    constructor(props: IntroTextScreenProps) {
        super(props);
    }


    get caption(): string{
        switch (this.user.userType) {
            case UserType.LANDLORD:
                return "Erzählen Sie kurz über sich!"
            case UserType.TENANT:
                return "Was möchtest du deinem zukünftigen Vermieter mitteilen?"
            case UserType.HOUSING_COMPANY:
                return "Unternehmensbeschreibung"
        }
        return 
    }

    async save(){
        // try {
        //     let res = await this.profileService.saveUser();
        //     console.log(res)
        // } catch (error) {
        //     console.log(error)
        // }
    }

    render() {
        return (
            <View style={styles.common}>
                <Text style={styles.caption}>{this.caption}</Text>
                
                <Textfield onChange={(text)=>{ this.setState({user:{introduction: text}}) }} value={this.state.user.introduction} height={200} outlined title="Nachricht verfassen ✍️" multiline ></Textfield>
                <View style={{margin: 25, marginBottom: 50}}>
                    <Button title="Speichern" variant={ButtonVariants.primary} onPress={()=>{this.save()}} ></Button>
                </View>
            </View> 
        );
    }
}

const styles = StyleSheet.create({
    common: {
        padding: 25,
        backgroundColor: "#ffffff",
        height: "100%"
    },
    caption:{
        fontSize: 25,
        marginBottom: 20

    }
    
    
})



export default IntroTextScreen;