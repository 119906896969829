import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { Disable, Pressable } from '../../types/componentInterfaces';
import Icon from './Icon';


export interface TileProps extends Disable, Pressable {
    text?: string
}

class Tile extends Component<TileProps> {


    constructor(props: TileProps) {
        super(props);
        
    }

    render() {
        return (
            <View>
                {this.props.disabled ? 
                    <View></View>
                :
                    <TouchableOpacity onPress={()=>{this.props.onPress ? this.props.onPress() : undefined }} style={{alignSelf: "flex-end", position: "absolute", top: 10, right: 9, zIndex: 10000}}>
                        <Icon  color='#274293' size={10} name={"close-circle-outline"}></Icon>
                    </TouchableOpacity>
                }
                <View style={styles.tile}>
                    <Text style={styles.text}>{this.props.text ? this.props.text : ""}</Text>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    tile:{
        backgroundColor: "#F0F4F8",
        padding: 15,
        borderRadius: 10,
        alignSelf: "flex-start",
        margin: 10
    },
    text:{
        color: "#A6BCD0",
        fontSize: 16
    },
    close:{
        position: "relative",
        
    }
   

})

export default Tile;