import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { ElevatedButton, RadioButtonGroup } from '../../../components/base';
import { ItemTypes } from '../../../types/propValueEnums';
import { ScrollView } from 'react-native-gesture-handler';
import { ListData } from '../../../types/dataStructures';
import { ItemService } from '../../../services';

export interface ApartmentNewPetsScreenProps extends Navigatable {

}

class ApartmentNewPetsScreen extends ApartmentUtilComponent<ApartmentNewPetsScreenProps> {

    state = { object: this.object, items: new Array<ListData>() }

    oldItems: ListData[];

    itemService = new ItemService();

    constructor(props: ApartmentNewPetsScreenProps) {
        super(props);

    }

    toggleFeature(feature_id: string){
        if(this.state.object.allowedPets){
            let newList: ListData[] = this.state.object.allowedPets;
            let id = newList.findIndex(f => 
                f?.id == feature_id
            );

            if(id == -1){
                let newItem = this.state.items.find(i => (i.id == feature_id))
                if(newItem)
                newList.push(newItem);                
            }
            else{
                newList.splice(newList.findIndex(i => (i.id == feature_id)), 1);
            }
            if(newList){
                this.setState({object: {allowedPets: newList}});
            }
        }
        else{
            let item = this.state.items.find(i => (i.id == feature_id))
            if(item)
                this.setState({object: {allowedPets: [item]}})
        }
    }

    toggleAll(i: number){
        if(i == 0){
            if(this.oldItems){
                this.setState({object: {allowedPets: this.oldItems, arePetsAllowed: true}})
            }
            else{
                this.setState({object: {arePetsAllowed: true}})
            }
        }
        else{
            if(this.state.object.allowedPets){
                this.oldItems = this.state.object.allowedPets;
            }
            this.setState({object: {allowedPets: undefined, arePetsAllowed: false}});
        }
    }

    async componentDidMount(){
        try {
            let items = await this.itemService.getItems(ItemTypes.PETS)
            this.setState({items});
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <View style={styles.screen}>

                <KeyboardAwareScrollView>
                    <View>
                        <ScrollView>
                            <View style={styles.content}>
                                <Text style={styles.caption}>Sind Haustiere erlaubt? * </Text>

                                <RadioButtonGroup  onChange={(index) => {this.toggleAll(index)}} horizontal buttons={[{label: 'Nein', value: false}, {label: 'Ja', value: true}]} ></RadioButtonGroup>
                                
                                {this.state.object.arePetsAllowed ? 
                                    <View style={{ flexDirection: "row", flexWrap: 'wrap', justifyContent: "space-between" }}>
                                        {this.state.items.map((item, index) => (
                                            <View key={index} style={styles.buttonWrapper}>
                                                <ElevatedButton onPress={() => { this.toggleFeature(item.id) }} isSelected={this.state.object.allowedPets && this.state.object.allowedPets.findIndex(f => (f.id == item.id)) != -1}>
                                                    <View style={styles.button}>
                                                        <Text style={styles.buttonText}>{item.name}</Text>
                                                    </View>
                                                </ElevatedButton>
                                            </View>
                                        ))}
                                    </View>
                                :
                                    <></>
                                }

                            </View>
                        </ScrollView>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
    },
    field: {
        width: 50
    },
    button: {
        height: 110,
        width: 100,
        margin: 3,
        backgroundColor: '#274293',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText:  {
        color: '#ffffff',
        fontSize: 13,
    },
    buttonWrapper: {
        margin: 5
    }

})



export default ApartmentNewPetsScreen;