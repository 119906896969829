import { FieldMapping } from "data-transformation"
import { IAPILocation } from "../../../ApiInterfaces"
import { Location } from "../../../dataStructures"

export const LocationToApiLocation: FieldMapping<Location, IAPILocation> = {
    altitude: 'altitude',
    city: src => src.city ? src.city.id : '',
    latitude: 'latitude',
    longitude: 'longitude',
    street: 'street',
    street_number: 'street_number',
    zip_code: 'zip_code'
}

