import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { Textfield } from '../../../components/base';
import { KeyboardType } from '../../../types/propValueEnums';

import Wohnung from "../../../assets/svg/Wohnung.svg";
import Zimmer from "../../../assets/svg/Zimmer.svg";


export interface ApartmentNewSizeScreenProps extends Navigatable {

}

class ApartmentNewSizeScreen extends ApartmentUtilComponent<ApartmentNewSizeScreenProps> {

    state = { object: this.object }

    rooms

    constructor(props: ApartmentNewSizeScreenProps) {
        super(props);
        this.rooms = React.createRef();
    }

    render() {
        return (
            <View style={styles.screen}>

                <KeyboardAwareScrollView>
                    <View>
                        <View style={styles.content}>
                            <Text style={styles.caption}>Wie groß ist das Objekt?</Text>
                            <View style={styles.row}>
                                <View style={{alignItems: "center", width: 50}}>
                                    <Wohnung height={50} ></Wohnung>
                                    <Text style={{fontSize: 10}}>Fläche</Text>
                                </View>
                                <View style={styles.field}>
                                    <Textfield returnKeyType="next" autofocus nextRef={this.rooms} keyboardType={KeyboardType.decimalPad} value={this.state.object.space ? this.state.object.space + "" : ""} onChange={(text) => { this.setState({ object: { space: text } }) }} title="Größe" ></Textfield>
                                </View>
                            </View>
                            <Text style={styles.caption}>Wie viel Zimmer hat das Objekt? </Text>
                            <View style={styles.row}>
                                <View style={{alignItems: "center", width: 50}}>
                                    <Zimmer height={50} ></Zimmer>
                                    <Text style={{fontSize: 10}}>Fläche</Text>
                                </View>
                                <View style={styles.field}>
                                    <Textfield ref={this.rooms} onSubmit={() => {this.props.navigation.navigate("Costs")}} keyboardType={KeyboardType.decimalPad} value={this.state.object.rooms ? this.state.object.rooms + "" : ""} onChange={(text) => { this.setState({ object: { rooms: text } }) }} title="Zimmer" ></Textfield>
                                </View>
                            </View>
                        </View>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
    },
    field: {
        width: 50
    },

})



export default ApartmentNewSizeScreen;