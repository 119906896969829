import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { Pressable, Variant, Disable } from '../../types/componentInterfaces';


export enum ButtonVariants{
    primary="primary",
    white="white",
    green="green",
    red="red",
    text="text",
    opaque="opaque",
    mint="mint",
    opaqueBlue="opaqueBlue",

}

export interface ButtonProps extends Pressable, Variant<ButtonVariants>, Disable {
    title: string,
    prefix?: any
   
}

class Button extends Component<ButtonProps> {

    constructor(props: ButtonProps) {
        super(props);
    }

    render() {
        let v: ButtonVariants;
        if(this.props.variant != undefined){
            v = this.props.variant
        }
        if(v == undefined || v == null ){
            v = ButtonVariants.primary
        }


        return (
            <TouchableOpacity disabled={this.props.disabled} onPress={this.props.onPress}>
                <View style={[buttonStyles.common, buttonStyles[this.props.variant], this.props.disabled ? {opacity: 0.5} : {}]}>
                    <View style={buttonStyles.prefix}>{this.props.prefix ? this.props.prefix : <View/>}</View>
                    <Text style={[textStyles.common, textStyles[this.props.variant]]} >
                        {this.props.title}
                    </Text>
                    <View style={buttonStyles.prefix}></View>
                </View>
            </TouchableOpacity>
        );
    }
}

const textStyles = StyleSheet.create({
    common: {
        fontSize: 15,
        textAlign: 'center',
    },
    primary:{
        color: "#ffffff",
    },
    text:{
        color: "#274293",
    },
    white:{
        color: "#274293"
    },
    red:{
        color: "#ffffff"
    },
    green:{
        color: "#ffffff"
    },
    opaque:{
        color: "#ffffff"
    },
    opaqueBlue:{
        color: "#274293"
    },
    mint:{
        color: "#ffffff"
    }


})
const buttonStyles = StyleSheet.create({
    common: {
        borderRadius: 5,
        height: 56,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"

    },
    primary:{
        backgroundColor: "#274293",
    },
    text:{
        backgroundColor: "transparent",
    },
    white:{
        backgroundColor: "#ffffff"
    },
    red: {
        backgroundColor: "#EB1111"
    },
    green:{
        backgroundColor: "#279344"
    },
    opaque: {
        borderColor: 'rgba(255, 255, 255, 0.25)',
        borderWidth: 2,
        backgroundColor: "transparent",
    },
    opaqueBlue: {
        borderColor: 'rgba(39, 66, 147, 0.20)',
        borderWidth: 2,
        backgroundColor: "transparent",
    },
    mint:{
        backgroundColor: "#6F7070B4",
        height: 48
    },
    prefix:{
        width: 40,
        marginLeft: 30,
        marginRight: 30
    }


})



export default Button;