import React, { Component } from 'react';
import { View, StyleSheet, Text, ImageBackground, Dimensions } from 'react-native';

import MBudeLogo from "../../assets/svg/MBude_Icon.svg";
import { Button, ButtonVariants } from '../../components/base';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { Navigatable, StartFlows } from '../../types/componentInterfaces';



const { width, height } = Dimensions.get('window');
export interface LandlordIntroScreenProps extends Navigatable {

}

const image = require('../../assets/image/welcome_background.png')
class LandlordIntroScreen extends Component<LandlordIntroScreenProps> {


    constructor(props: LandlordIntroScreenProps) {
        super(props);
    }

    toWelcome() {
        this.props.navigation.navigate("Welcome", { startFlow: StartFlows.LANDLORD })
    }


    render() {
        return (
            <ImageBackground source={image} style={styles.background} imageStyle={styles.backgroundImage}>
                <View style={styles.backgroundColor}>
                    <View style={styles.logo}>
                        <MBudeLogo height={47} width={56} ></MBudeLogo>
                        <Text style={styles.subCaption}>Meine Bude</Text>
                    </View>
                    <SwiperFlatList index={0} showPagination style={styles.swiperContainer} paginationStyle={styles.pagination}>            
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>REGISTRIEREN</Text>
                            <Text style={styles.swiperText}>Registriere dich mit deiner E-Mail-Adresse, deinem Namen und einem Passwort, um deinen Wunsch-Mieter zu finden.</Text>
                        </View>
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>WOHNUNG INSIRIEREN</Text>
                            <Text style={styles.swiperText}>Inseriere deine Wohnung und finde passende Mieter für deine Wohnung!</Text>
                        </View>
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>FILTER EINSTELLEN</Text>
                            <Text style={styles.swiperText}>Filtere potenzielle Mieter schnell und einfach nach den Kriterien, die du hast!</Text>
                        </View>
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>MIETER/IN AUSSUCHEN</Text>
                            <Text style={styles.swiperText}>Sieh dir die Profile der potenziellen Mieter an und entscheide selbst, welchen Mieter du haben möchtest.</Text>
                        </View>
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>BESICHTIGUNGSTERMINE</Text>
                            <Text style={styles.swiperText}>Besichtigungstermine kannst du vorab in der App festhalten, so erleichterst du die Kommunikation mit den potenziellen Mietern.</Text>
                        </View>
                        {/* <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>Wohnung vermieten</Text>
                            <Text style={styles.swiperText}>Besichtigen die Wohnungen, die dir gefallen Und entscheiden, ob du dich noch live besichtigen Möchtest oder direkt mieten möchtest.</Text>
                        </View> */}
                    </SwiperFlatList>
                    <View style={styles.buttons}>
                        <View style={{ marginTop: 20 }}>
                            <Button onPress={() => { this.toWelcome() }} title="Jetzt loslegen" variant={ButtonVariants.opaque} ></Button>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        );
    }
}


const styles = StyleSheet.create({
    backgroundColor: {
        backgroundColor: '#274293D1',
        justifyContent: "center",
        height: "100%",
    },
    common: {
        padding: 25,
        justifyContent: "center"

    },
    caption: {
        fontSize: 45,
        textAlign: "center",
        color: "#ffffff"
    },
    logo: {
        marginTop: 70,
        alignItems: "center",

    },
    subCaption: {
        margin: 17,
        color: '#ffffff'
    },
    slogan: {
        color: '#ffffff'
    },
    buttons: {
        padding: 25,
        marginTop: "auto"
    },
    backgroundImage: {
        position: 'absolute',
        top: 0,
        bottom: "45%",
        resizeMode: "cover",


    },
    background: {
        width: "100%",
        height: "100%",
        flex: 1,
        position: "relative"
    },
    swiperText:{
        color: '#ffffff',
         textAlign: "center",
         width:width*0.8,
    },
    swiperCaption:{
        fontSize: 22,
        marginBottom: 50
    },
    swiperSlide:{
        width,
        alignItems: 'center',
        height: height*0.5,
    },
    swiperContainer:{
        flex:1,
        height:height*0.5,
        marginTop:height*0.1,
        width:width,
    },
    pagination:{
        marginBottom:height*0.2,
    }

})



export default LandlordIntroScreen;