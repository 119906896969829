import { ApartmentService } from "../services";
import { RentObject } from "./dataStructures";
import { UtilComponent } from "./UtilComponent";

export class ApartmentUtilComponent <Props> extends UtilComponent<Props>{

    protected apartmentService = new ApartmentService();

    get object(): RentObject{
        return this.apartmentService.newRentObject
    }

    set object(val: RentObject) {
        this.apartmentService.newRentObject = val;
    }

    constructor(props: Props){
        super(props);
    }

    setState(newState, callback?: any): Promise<void>{
        return new Promise((resolve) => {
            if(newState.object){
                Object.assign(this.object, newState.object)
                newState.object = this.object;
            }
            let cb = ()=> {
                if(callback)
                    callback()
                resolve()
            }
            super.setState(newState, cb)
        })
    }
}