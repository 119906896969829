import React from 'react';
import { View, StyleSheet, Text  } from 'react-native';

import * as Validator from "validator";
import { ArrowButton, Button, ButtonVariants, Textfield } from '../../components/base';
import { AuthService, ProfileService, StorageService } from '../../services';
import { Navigatable } from '../../types/componentInterfaces';
import { User } from '../../types/dataStructures';
import { KeyboardType, TextContentType } from '../../types/propValueEnums';
import { UtilComponent } from '../../types/UtilComponent';


export interface LoginScreenProps extends Navigatable {

}

class LoginScreen extends UtilComponent<LoginScreenProps> {

    state = { password: "", username: "", userError: "", pwError: "", loading: false }

    auth = new AuthService();
    profileService = new ProfileService();
    storage = new StorageService()

    refPassword;

    constructor(props: LoginScreenProps) {
        super(props);

        this.refPassword = React.createRef();
    }

    async login(){
        try {
            await this.setState({loading: true, userError: "", pwError: ""})
            if(!Validator.isEmail(this.state.username)){
                this.setState({userError: "Bitte gebe eine gültige Email-Adresse ein."})
                return
            }
            if(Validator.isEmpty(this.state.password)){
                this.setState({pwError: "Bitte gebe dein Passwort ein."})
                return
            }
            let status = await this.auth.login( this.state.username, this.state.password);
            await this.storage.setItem("status", JSON.stringify(status))
            
            let profile: User = await this.profileService.getMyProfile();
            
            this.profileService.user = profile
            this.auth.authCallback ? await this.auth.authCallback() : console.log("Callback not defined") 

        } catch (error) {
            console.log(error)
            switch (error.message) {
                case 'user not found':
                    this.setState({userError: error.msg})
                break;
                
                case 'incorrect password':
                        this.setState({pwError: error.msg})
                break;
                        
                default:
                    this.setState({userError: error.msg})
                break;
            }
            
        } finally {
            this.setState({loading: false})
        }
    }

    back(){
        this.props.navigation.goBack()
    }

    render() {
        return (
            <View>
                <View style={loginStyles.common}>
                    {this.props.navigation.canGoBack() ? 
                        <View style={{alignItems: 'flex-start'}}>
                            <ArrowButton onPress={()=>{this.back()}} back chevron color="#4892F1" ></ArrowButton>
                        </View>
                    :
                    <></>
                    }
                    <View style={loginStyles.content}>
                        <Text style={loginStyles.caption}>Login <Text style={loginStyles.icon} >👋</Text></Text>
                        <Text style={loginStyles.sub}>Melde dich an oder registriere dich</Text>
                        <View style={loginStyles.fields}>
                            <Textfield testID="username" returnKeyType="next" onSubmit={()=>{this.refPassword.current.focus()}} style={{marginTop: 5}} error={this.state.userError} value={this.state.username} onChange={(text)=>{this.setState({username: text})}} title="Email-Adresse" keyboardType={KeyboardType.emailAddress} textContentType={TextContentType.username} ></Textfield>
                            <Textfield testID="password" returnKeyType="send" onSubmit={()=>{this.login()}} ref={this.refPassword} style={{marginTop: 5}} error={this.state.pwError} value={this.state.password} onChange={(text)=>{this.setState({password: text})}} title="Passwort" textContentType={TextContentType.password} autoCorrect={false} secureTextEntry={true}></Textfield>
                        </View>
                        <View style={loginStyles.buttons}> 
                            <Button testID="loginButton" loading={this.state.loading} title="Anmelden" onPress={()=>{this.login()}} variant={ButtonVariants.primary} ></Button>
                            <Button title="Passwort zurücksetzen" variant={ButtonVariants.text} ></Button>
                        </View>
                    </View>
                </View>
                <View style={loginStyles.bottom}>
                    <Button title="Registrieren" onPress={()=>{this.props.navigation.navigate("WelcomeFlow")}} variant={ButtonVariants.text} ></Button>
                </View>


            </View>
        );
    }
}

const loginStyles = StyleSheet.create({
    common:{
        padding: 25,
        backgroundColor: "#ffffff"
    },
    content:{
        marginTop: 25,
        margin: 4
    },
    caption: {
        fontFamily: "SF Pro Display",
        fontSize: 34
    },
    sub:{
        marginTop: 10,
        color: "#767984"
    },
    icon:{
        fontSize: 30
    },
    fields:{
        marginTop: 60
    },
    buttons:{
        marginTop: 60
    },
    bottom:{
        height: 125,
        
        backgroundColor: '#F5F6F9'
    }
})



export default LoginScreen;