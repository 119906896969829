import { FieldMapping } from "data-transformation"
import { Person, User } from "../../../dataStructures"

export const UserProfileToApiPerson: FieldMapping<User, Person> = {
    firstname: 'firstname',
    surname: 'lastname',
    introduction: 'introduction',
    title: 'title',
    phone_number: 'phone_number',
    personal_status_id: (src: User) => src.personal_status.id
}

