import React, { Component } from 'react';
import { View, StyleSheet, Text, ImageBackground, Image  } from 'react-native';

import MBudeLogo from "../../assets/svg/MBude_Icon.svg";
import { Button, ButtonVariants } from '../../components/base';
import { Navigatable, RegisterFlow, RegisterFlows, Route, StartFlow, StartFlows } from '../../types/componentInterfaces';



export interface WelcomeScreenProps extends Navigatable, Route<StartFlow> {

}

const image = require('../../assets/image/welcome_background.png')
class WelcomeScreen extends Component<WelcomeScreenProps> {

    state = {state: "reg_log"}

    constructor(props: WelcomeScreenProps) {
        super(props);
    }

    toLogin(){
        this.props.navigation.navigate("Login")
    }

    toRegister(flow: RegisterFlows){
        if(flow == RegisterFlows.HOUSING_COMPANY){
            this.props.navigation.navigate<RegisterFlow>("registerCompany", {registerFlow: flow})
        }
        else{
            this.props.navigation.navigate<RegisterFlow>("registerTitle", {registerFlow: flow})
        }
    }

    renderButtons(){
        let buttons;
        switch (this.state.state) {
            case "reg_log":
                if(this.props.route.params.startFlow == StartFlows.LANDLORD){
                    buttons = 
                    <View>
                        <Button onPress={()=>{this.toLogin()}} title="Login" variant={ButtonVariants.white} ></Button>
                        <View style={{marginTop: 20}}>
                            <Button onPress={()=>{this.setState({state: "register"})}} title="Registrieren" variant={ButtonVariants.opaque} ></Button>
                        </View>
                    </View>
                }
                else{
                    buttons = 
                    <View>
                        <Button onPress={()=>{this.toLogin()}} title="Login" variant={ButtonVariants.white} ></Button>
                        <View style={{marginTop: 20}}>
                            <Button onPress={()=>{this.toRegister(RegisterFlows.TENANT)}} title="Registrieren" variant={ButtonVariants.opaque} ></Button>
                        </View>
                    </View>
                }
            break;
            case "register":
                buttons = 
                <View>
                    <Button onPress={()=>{this.toRegister(RegisterFlows.LANDLORD)}} title="Privater Vermieter" variant={ButtonVariants.white} ></Button>
                    <View style={{marginTop: 20}}>
                        <Button onPress={()=>{this.toRegister(RegisterFlows.HOUSING_COMPANY)}} title="Wohnungsgesellschaft" variant={ButtonVariants.opaque} ></Button> 
                    </View>
                </View>
            break;
        
            default:
                break;
        }

        return buttons;
    }


    render() {
        return (
            <ImageBackground source={image} style={styles.background} imageStyle={styles.backgroundImage}>
                <View style={styles.common}>
                    <Text style={styles.caption} >Willkommen</Text>
                    <View style={styles.logo}>
                        <MBudeLogo height={47} width={56} ></MBudeLogo>
                        <Text style={styles.subCaption}>Meine Bude</Text>
                        <Text style={styles.slogan}>HIER FINDEST ODER VERGIBST DU</Text>
                        <Text style={styles.slogan}>DEINE WOHNUNG SCHNELL UND EINFACH</Text>
                        {/* <Text style={styles.slogan}>HIER SUCHST DU NICHT</Text>
                        <Text style={styles.slogan}>HIER FINDEST DU DEINE TRAUM-WOHNUNG</Text> */}
                    </View>
                    <View style={styles.buttons}>
                        {this.renderButtons()}
                    </View>

                </View>
            </ImageBackground>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        padding: 25,
        backgroundColor: '#274293D1',
        height: "100%",
        justifyContent: "center"

    },
    caption:{
        fontSize: 45,
        textAlign: "center",
        color: "#ffffff"
    },
    logo:{
        marginTop: 70,
        alignItems: "center",
        
    },
    subCaption:{
        margin: 17,
        color: '#ffffff'
    },
    slogan:{
        color: '#ffffff'
    },
    buttons: {
        padding: 25,
        marginTop: "auto"
    },
    backgroundImage:{
        position: 'absolute',
        top: 0,
        bottom:"45%",
        resizeMode: "cover",
        
        
    },
    background:{
        width: "100%",
        height: "100%",
        flex: 1,
        position: "relative"
    }
    
})



export default WelcomeScreen;