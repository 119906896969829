import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { Disable, Pressable, Testable } from '../../types/componentInterfaces';
import { Icon } from './index';



export interface ArrowButtonProps extends  Disable, Pressable, Testable {
    back?: boolean;
    chevron?: boolean;
    color?: string;
}

class ArrowButton extends Component<ArrowButtonProps> {

    back: string = `ios-arrow${ this.props.chevron ? '' : '-round'}-back`;
    forward: string = `ios-arrow${ this.props.chevron ? '' : '-round'}-forward`;

    constructor(props: ArrowButtonProps) {
        super(props);
        
    }

    getColor(){
        if(this.props.disabled){
            return '#707070'
        }
        return this.props.color ? this.props.color : '#274293'

    }

    render() {
        return (
            <TouchableOpacity onPress={!this.props.disabled ? this.props.onPress : undefined}>
                <Icon color={this.getColor()} size={this.props.chevron? 30 : 40} name={this.props.back ? this.back : this.forward}></Icon>
            </TouchableOpacity>
        );
    }
}


export default ArrowButton;