import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { Component, useState } from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import Button, { ButtonVariants } from '../../../components/base/Button';
import Header from '../../../components/base/Header';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import AddressScreen from './Address';
import IntroTextScreen from './IntroText';
import PersonalScreen from './Personal';


const Tab = createMaterialTopTabNavigator();

export interface ProfileBaseScreenProps extends Navigatable {

}

class ProfileBaseScreen extends Component<ProfileBaseScreenProps> {

    state = {}

    constructor(props: ProfileBaseScreenProps) {
        super(props);
    }

    back() {
        
        this.props.navigation.goBack()
    }

    render() {
        return (
            <View style={styles.common}>
                <Header onBack={()=>{this.back()}} title="Profil" ></Header>
                <View style={styles.line} ></View>
                
                    <Tab.Navigator initialLayout={{height: 100, width: 100}} backBehavior={"none"}>
                        <Tab.Screen name="Vorstellung" component={IntroTextScreen} />
                        <Tab.Screen name="Persönlich" component={PersonalScreen} />
                        <Tab.Screen name="Adresse" component={AddressScreen} />
                    </Tab.Navigator>            
                
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        height: "100%",
        width: "100%",
        backgroundColor: '#ffffff'
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        margin: 2
    },
    
})



export default ProfileBaseScreen;