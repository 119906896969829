import React, { Component } from 'react';
import { Text, StyleSheet, Animated, View, Easing } from 'react-native';

export interface AnimatedTitleProps {
  hasText: boolean
  title: string
}


export default class AnimatedTitle extends Component<AnimatedTitleProps> {
  
  state = { height: 0 , translationY: new Animated.Value(0)}
  
  inited: boolean = false;
  
  size = this.state.translationY.interpolate({
    inputRange: [0, this.state.height],
    outputRange: [10, 14]
  });
  

  constructor(props: AnimatedTitleProps) {
    super(props);
  }

  animate(toValue: number){
    Animated.timing(this.state.translationY, {
      toValue: toValue,
      duration: 150,
      useNativeDriver: false      
    }).start()
  }

  


  onFocus() {
    this.animate(0)
  }

  onBlur() {
    !this.props.hasText ? this.animate(this.state.height) : undefined; 
  }

  measureView(event) {
    let h = Math.max(event.nativeEvent.layout.height - 22, 0);
    this.setState({height: h, translationY: new Animated.Value(this.props.hasText ? 0 : h)});
    if(!this.inited){
      this.setState({translationY: new Animated.Value(this.props.hasText ? 0 : h)});
      this.inited = true;
    }
  }


  render() {
    return (
      <View>
        <Animated.View style={[{transform:[{translateY: this.state.translationY}]}]}>
          <Animated.Text 
            style={[{ height: 18, fontSize: 
              this.state.translationY.interpolate({ 
                inputRange: [0, this.state.height],
                outputRange: [10,14]
            }),
            color: '#70747F'
  
            }]}>{this.props.title}</Animated.Text>
        </Animated.View>
        <View onLayout={(event) => {this.measureView(event)}} >{this.props.children}</View>
      </View>

    );
  }
}

const styles = StyleSheet.create({

});