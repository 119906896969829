import 'react-native-gesture-handler';
import React from 'react';
import {
  StyleSheet,
  View,
} from 'react-native';

import { NavigationContainer } from '@react-navigation/native';

import { createStackNavigator,  } from '@react-navigation/stack';

import LoginScreen from './src/pages/auth/Login';
import WelcomeScreen from './src/pages/start/Welcome';
import TitleScreen from './src/pages/register/Title';
import NameScreen from './src/pages/register/Name';
import EmailScreen from './src/pages/register/Email';
import PasswordScreen from './src/pages/register/Password';
import FinishScreen from './src/pages/register/Finish';
import BirthdayScreen from './src/pages/register/Birthday';
import CompanyScreen from './src/pages/register/Company';
import WelcomeFlowScreen from './src/pages/start/WelcomeFlow';
import TenantIntroScreen from './src/pages/start/TenantIntro';
import LandlordIntroScreen from './src/pages/start/LandlordIntro';
import NavigationScreen from './src/pages/profile/Navigation';
import ProfileBaseScreen from './src/pages/profile/UserProfile/ProfileBase';
import ViewingRequestScreen from './src/pages/profile/ViewingRequest';
import ProfileCompletionScreen from './src/pages/profile/UserProfile/ProfileCompletcion';
import { StorageService } from './src/services/Storage.Service';
import { UtilComponent } from './src/types/UtilComponent';
import { AuthService } from './src/services/Auth.Service';
import { ProfileService } from './src/services/Profile.Service';
import { UserType } from './src/types/dataStructures/UserType';
import { UserTypeContext } from './src/types/UserTypeContext';
import NewApartmentBaseScreen from './src/pages/apartments/newApartment/Apartment.New.Base';
import ApartmentDetailScreen from './src/pages/apartments/ApartmentDetail';
import ApartmentOverviewScreen from './src/pages/apartments/ApartmentOverview';
import FilterPayment from './src/pages/apartments/FilterPayment';
import TenantDetailScreen from './src/pages/apartments/TenantDetail/TenantDetail';

const Stack = createStackNavigator();



export class App extends UtilComponent<any>{

  state = {isSignedIn: undefined, type: undefined}

  storage = new StorageService();

  auth = new AuthService();

  profileService = new ProfileService();

  async componentDidMount(){
    this.auth.authCallback = this.getAuthStatus.bind(this);
    this.getAuthStatus();
  }
  
  async getAuthStatus(){
    let status = await this.storage.getItem("status")
    if(status){
      try {
        let profile = await this.profileService.getMyProfile();
        this.setState({type: profile.userType});
      } catch (error) {
        console.log(error)
      }
    }
    await this.setState({isSignedIn : status != undefined});
  }

  render(){
    return (
      this.state.isSignedIn == undefined ? 
        <View></View>
        :
        <UserTypeContext.Provider value={this.state}>
          <NavigationContainer>
            <Stack.Navigator>
              {this.state.isSignedIn ? 
                <>
                {this.state.type == UserType.TENANT ? 
                  <Stack.Screen options={{headerShown: false}} name="ProfileCompletion" component={ProfileCompletionScreen} />
                  :
                  <Stack.Screen options={{headerShown: false}} name="ProfileNavigation" component={NavigationScreen} />
                }
                <Stack.Screen options={{headerShown: false}} name="ProfileBase" component={ProfileBaseScreen} />
                <Stack.Screen options={{headerShown: false}} name="ApartmentOverview" component={ApartmentOverviewScreen} />
                <Stack.Screen options={{headerShown: false}} name="ApartmentDetail" component={ApartmentDetailScreen} />
                <Stack.Screen options={{headerShown: false}} name="FilterPayment" component={FilterPayment} />
                <Stack.Screen options={{headerShown: false}} name="ViewingRequests" component={ViewingRequestScreen} />
                <Stack.Screen options={{headerShown: false}} name="TenantDetail" component={TenantDetailScreen} />
                <Stack.Screen options={{headerShown: false}} name="NewApartment" component={NewApartmentBaseScreen} />
                </>
              :
              <>
              <Stack.Screen options={{headerShown: false}} name="WelcomeFlow" component={WelcomeFlowScreen} />
              <Stack.Screen options={{headerShown: false}} name="Welcome" component={WelcomeScreen} />
              <Stack.Screen options={{headerShown: false}} name="Login" component={LoginScreen} />
              <Stack.Screen options={{headerShown: false}} name="TenantIntro" component={TenantIntroScreen} />
              <Stack.Screen options={{headerShown: false}} name="LandlordIntro" component={LandlordIntroScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerCompany" component={CompanyScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerTitle" component={TitleScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerName" component={NameScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerBirthday" component={BirthdayScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerEmail" component={EmailScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerPassword" component={PasswordScreen} />
              <Stack.Screen options={{headerShown: false}} name="registerFinish" component={FinishScreen} />
              </>
              } 
              
            </Stack.Navigator>
          </NavigationContainer>
        </UserTypeContext.Provider>
    )
  }

}

const styles = StyleSheet.create({
  button: {
    marginHorizontal: 56,
    
  },
  
  background:{
    backgroundColor: "blue",
    height: "100%"
  }
  
});

export default App;
