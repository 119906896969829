import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { Textfield } from '../../../components/base';
import { KeyboardType } from '../../../types/propValueEnums';
import { ScrollView } from 'react-native-gesture-handler';

export interface ApartmentNewCostsScreenProps extends Navigatable {

}

class ApartmentNewCostsScreen extends ApartmentUtilComponent<ApartmentNewCostsScreenProps> {

    state = { object: this.object }

    
    warm;
    kaution;
    prov;
    energy;


    constructor(props: ApartmentNewCostsScreenProps) {
        super(props);
        this.warm = React.createRef();
        this.kaution = React.createRef();
        this.prov = React.createRef();
        this.energy = React.createRef();
    }

    setCold(newCold: string){
        this.setState({ object: { rent_cold: newCold } })
        if(this.state.object.rent_warm){
            try {
                let comp = Number(this.state.object.rent_warm) + Number(newCold)
                this.setState({object: {rent_complete: comp}});
            } catch (error) {
                console.log('could parse rents')
            }
        }
    }
    
    setWarm(newWarm: string){
        this.setState({ object: { rent_warm: newWarm } })
        if(this.state.object.rent_cold){
            try {
                let comp = Number(this.state.object.rent_warm) + Number(newWarm)
                this.setState({object: {rent_complete: comp}});
            } catch (error) {
                console.log('could parse rents')
            }
        }
    }

    render() {
        return (
            <View style={styles.screen}>

                <KeyboardAwareScrollView>
                    <View>
                        <ScrollView>
                            <View style={styles.content}>
                                <Text style={styles.caption}>Was kostet das Objekt?</Text>

                                <Text style={{ marginTop: 25 }}>Kosten Miete</Text>
                                <View style={styles.field}>
                                    <Textfield autofocus nextRef={this.warm} keyboardType={KeyboardType.decimalPad} value={this.state.object.rent_cold ? this.state.object.rent_cold + "" : ""} onChange={(text) => { this.setCold(text) }} title="Kaltmiete" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <Textfield returnKeyType="next" ref={this.warm} nextRef={this.kaution} keyboardType={KeyboardType.decimalPad} value={this.state.object.rent_warm ? this.state.object.rent_warm + "" : ""} onChange={(text) => { this.setWarm(text) }} title="Nebenkosten" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <Textfield  keyboardType={KeyboardType.decimalPad} disabled editable={false} value={this.state.object.rent_cold && this.state.object.rent_warm ? (Number(this.state.object.rent_cold) + Number(this.state.object.rent_warm)) + "" : ""} title="Gesamtpreis" ></Textfield>
                                </View>

                                <Text style={{ marginTop: 25 }}>Sonstige Kosten</Text>
                                <View style={styles.field}>
                                    <Textfield returnKeyType="next" ref={this.kaution} nextRef={this.prov} keyboardType={KeyboardType.decimalPad} value={this.state.object.kaution ? this.state.object.kaution + "" : ""} onChange={(text) => { this.setState({ object: { kaution: text } }) }} title="Kaution / Genossenschaftsanteile" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <Textfield returnKeyType="next" ref={this.prov} nextRef={this.energy} keyboardType={KeyboardType.decimalPad} value={this.state.object.provision ? this.state.object.provision + "" : ""} onChange={(text) => { this.setState({ object: { provision: text } }) }} title="Provision" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <Textfield ref={this.energy} onSubmit={()=> {this.props.navigation.navigate("Features")}} keyboardType={KeyboardType.decimalPad} value={this.state.object.energy ? this.state.object.energy + "" : ""} onChange={(text) => { this.setState({ object: { energy: text } }) }} title="Energie Verbrauchskennwert" ></Textfield>
                                </View>

                            </View>
                        </ScrollView>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
    },
    field: {
        marginBottom: 10
    },

})



export default ApartmentNewCostsScreen;