import React from 'react';
import { Image, Text, TouchableOpacity, View, StyleSheet, ScrollView, RefreshControl, Alert } from 'react-native';

import User from '../../assets/svg/User.svg'

import { Picker } from '@react-native-picker/picker';
import { Navigatable, Route } from '../../types/componentInterfaces';
import { RentObject } from '../../types/dataStructures';
import { Button, ButtonVariants, Header, RadioButtonGroup, Textfield } from '../../components/base';
import { KeyboardType } from '../../types/propValueEnums';
import { ApartmentUtilComponent } from '../../types/ApartmentUtilComponent';
import { MenuView } from '@react-native-menu/menu';
import { Platform } from 'react-native';
import { Icon } from '../../components/base/index';


export interface ApartmentDetailScreenProps extends Navigatable, Route<number> {

}

class ApartmentDetailScreen extends ApartmentUtilComponent<ApartmentDetailScreenProps> {

    apartment: Partial<RentObject> = {};

    tenants: Array<any> = new Array();

    filter = {
        petsAllowed: true,
        smoker: true,
        smokeInside: true,
        maxTenants: 2,
        kidsAllowed: true,
        family: ""
    }


    state = { refreshing: false, apartment: this.apartment, tenants: this.tenants, isList: true, filter: this.filter }

    constructor(props: ApartmentDetailScreenProps) {
        super(props);
    }

    async componentDidMount() {
        this.onRefresh();
    }

    toTenant(tenant: any) {
        this.props.navigation.navigate("TenantDetail", { tenant: tenant, apartment: this.state.apartment })
    }

    toggleFilter() {
        this.setState({ isList: !this.state.isList })
    }

    setPets(index: number) {
        this.filter.petsAllowed = index == 0;
    }

    setSmoker(index: number) {
        this.filter.smoker = index == 0;
    }

    setSmokeInside(index: number) {
        this.filter.smokeInside = index == 0;
    }

    setKidsAllowed(index: number) {
        this.filter.kidsAllowed = index == 0;
    }

    showDeletePrompt() {
        Alert.alert("Inserat wirklich Löschen?", "",
            [
                {
                    text: "Abbrechen",
                    style: "cancel",
                },
                {
                    text: "Löschen",
                    onPress: () => { this.deleteInserat() },
                    style: "destructive",
                },
            ],
            {
                cancelable: true,
            })

    }

    async deleteInserat() {
        try {
            let res = await this.apartmentService.deleteObject(this.apartment.id);
            if (res) {
                this.apartmentService.updateObjectList()
                this.props.navigation.goBack();
            }
        } catch (error) {
            console.log(error)
            Alert.alert("Inserat konnte nicht gelöscht werden.", error.msg);
        }
    }

    async onRefresh() {
        this.setState({ refreshing: true })
        try {
            this.apartment = this.apartmentService.getApartment(this.props.route.params);
            this.setState({ apartment: this.apartment, tenants: this.apartment.tenants });
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({ refreshing: false })

        }
    }

    toFilterPayment() {
        this.props.navigation.navigate("FilterPayment")
    }

    renderList() {
        return this.state.tenants ?
            this.state.tenants?.map((tenant, index) => (
                <TouchableOpacity onPress={() => { this.toTenant(tenant) }} key={index}>
                    <View style={styles.listElement}>
                        <User></User>
                        <View style={{ padding: 10 }}>
                            <Text style={{ fontWeight: "bold", marginBottom: 5 }}>{tenant.firstname} {tenant.lastname}, {tenant.profession}</Text>
                            <Text style={{ marginBottom: 15 }}>Sucht: {tenant.desiredRooms} Zimmer, Budget: {tenant.budgetMin} - {tenant.budgetMax}</Text>
                            <Text style={{ marginBottom: 5 }}>Erwartete Austattung:</Text>
                            <Text>
                                {tenant.desiredFeatures.map((feature, index, array) => (
                                    <Text style={{ fontSize: 12, color: "#767984" }} key={index}>{feature}{index == array.length - 1 ? "" : " | "}</Text>
                                ))}
                            </Text>
                        </View>
                    </View>
                </TouchableOpacity>
            ))
            :
            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Text>Keine passenden Mieter gefunden...</Text>
            </View>
    }

    renderMenu() {
        return (
            <MenuView
                title="Aktionen"
                shouldOpenOnLongPress={false}
                onPressAction={({ nativeEvent }) => {
                    if (nativeEvent.event == 'edit') {
                        this.object = this.apartment;
                        this.apartmentService.isEditFlow = true;
                        this.props.navigation.navigate("NewApartment");
                    }
                    else if (nativeEvent.event == 'delete') {
                        this.showDeletePrompt();
                    }
                }}
                actions={[
                    {
                        id: 'edit',
                        title: "Inserat bearbeiten",
                        titleColor: '#363636',
                        image: Platform.select({
                            ios: 'edit',
                            android: 'ic_menu_edit',
                        }),
                        imageColor: '#363636',

                    },
                    {
                        id: 'delete',
                        title: "Inserat löschen",
                        titleColor: 'red',
                        image: Platform.select({
                            ios: 'delete',
                            android: 'ic_menu_delete',
                        }),
                        imageColor: 'red',
                    },
                ]}
            >
                <TouchableOpacity style={{width: 35, height:25, alignItems: 'center'}}>
                    <Icon name="more" color="#4892F1" ></Icon>
                </TouchableOpacity>
            </MenuView>
        )
    }

    render() {
        return (
            <View style={styles.screen}>
                <Header onBack={this.props.navigation.canGoBack ? this.props.navigation.goBack : undefined} >{this.renderMenu()}</Header>
                <View style={styles.line} ></View>
                <ScrollView refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => { this.onRefresh() }} />} >
                    <View >
                        {this.apartment.thumbnail ?
                            <Image style={styles.pictures} source={{ uri: this.apartment.thumbnail }} ></Image>
                            :
                            <View style={[styles.pictures, { justifyContent: 'center', alignItems: 'center' }]}>
                                <Text>Keine Bilder vorhanden...</Text>
                            </View>
                        }
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ marginRight: "auto" }} ></View>
                        <View style={styles.badge}>
                            <Text style={styles.badgeText}>{this.apartment.matches ? this.apartment.matches : 0} passende Profile</Text>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <Text>{this.apartment.address?.city.name}</Text>
                        <Text style={{ marginBottom: 15, fontSize: 20, marginTop: 5 }} >{this.apartment.rooms} Zimmer | {this.apartment.space} m² | {this.apartment.rent_cold}€ Kalt</Text>
                        <Button title={this.state.isList ? "Filter einsetzen" : "zurück"} onPress={() => { this.toggleFilter() }} variant={ButtonVariants.mint} ></Button>
                    </View>
                    {this.state.isList ?
                        this.renderList()
                        :
                        <View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ marginRight: "auto", paddingLeft: 25, fontSize: 20, fontWeight: "bold" }}>Setzen Sie Ihren Filter!</Text>
                                <View style={styles.badge}>
                                    <Text style={styles.badgeText}>Preis 2,50€</Text>
                                </View>
                            </View>
                            <View style={[styles.content, { marginTop: 10 }]}>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Familienstand</Text>
                                    <Picker accessibilityLabel="Familienstand" onValueChange={(itemValue) => { this.setState({ filter: { family: String(itemValue) } }) }}>
                                        <Picker.Item label="ledig" value="ledig" />
                                        <Picker.Item label="verheiratet" value="verheiratet" />
                                        <Picker.Item label="geschieden" value="geschieden" />
                                    </Picker>

                                </View>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Sind Haustiere erlaubt?</Text>
                                    <RadioButtonGroup onChange={(index) => { this.setPets(index) }} horizontal buttons={[{ label: "Ja", value: false }, { label: "Nein", value: false }]} ></RadioButtonGroup>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Darf Mieter rauchen?</Text>
                                    <RadioButtonGroup onChange={(index) => { this.setSmoker(index) }} horizontal buttons={[{ label: "Ja", value: false }, { label: "Nein", value: false }]} ></RadioButtonGroup>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Darf in der Wohnung geraucht werden?</Text>
                                    <RadioButtonGroup onChange={(index) => { this.setSmokeInside(index) }} horizontal buttons={[{ label: "Ja", value: false }, { label: "Nein", value: false }]} ></RadioButtonGroup>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Wie viel Menschen dürfen maximal einziehen?</Text>
                                    <Textfield keyboardType={KeyboardType.numberPad} value={String(this.state.filter.maxTenants)} onChange={(text) => { this.setState({ filter: { maxTenants: text } }) }}></Textfield>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 18 }}>Dürfen Kinder mit einziehen?</Text>
                                    <RadioButtonGroup onChange={(index) => { this.setKidsAllowed(index) }} horizontal buttons={[{ label: "Ja", value: false }, { label: "Nein", value: false }]} ></RadioButtonGroup>
                                </View>
                                <View style={{ marginTop: 20, marginBottom: 20, }}>
                                    <Button onPress={() => { this.toFilterPayment() }} title="Filter anwenden" variant={ButtonVariants.green} ></Button>
                                </View>
                            </View>

                        </View>
                    }

                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
        paddingTop: 0
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    bold: {
        fontWeight: "bold"
    },
    caption: {
        fontSize: 30,
        marginBottom: 20,
        marginRight: "auto"
    },
    listElement: {
        borderColor: "#9EAACF",
        borderWidth: 1,
        borderStyle: 'solid',
        margin: 25,
        marginBottom: 15,
        flexDirection: "row"
    },
    thumbnail: {
        width: "30%",
        height: 100
    },
    picture: {
        width: "100%",
        height: "100%"
    },
    pictures: {
        width: "100%",
        height: 200
    },
    text: {
        padding: 8
    },
    badge: {
        backgroundColor: "#274293",
        padding: 10,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
        marginTop: -10
    },
    badgeText: {
        color: "#ffffff"
    }

})



export default ApartmentDetailScreen;