import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import RadioButtonGroup from '../../../components/base/RadioButtonGroup';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import { Route } from '../../../types/componentInterfaces/Route';


export interface TenantProfessionTabProps extends Navigatable, Route<Tenant> {

}

class TenantProfessionTab extends Component<TenantProfessionTabProps> {

    state = { }

    constructor(props: TenantProfessionTabProps) {
        super(props);
    }


    render() {
        let tenant = this.props.route.params;

        let limitation = tenant.contractLimitation ? DateTime.fromISO(tenant.contractLimitation).toFormat("dd.MM.yyyy") : "";

        return (
            <View style={[styles.screen, styles.content]}>
                <View style={styles.section}>
                    <Text style={styles.caption}>Beschäftigungsart</Text>
                    <Text style={styles.text}>{tenant.contractType}</Text>
                    <View style={[styles.line]} ></View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.caption}>Branche</Text>
                    <Text style={styles.text}>{tenant.branch}</Text>
                    <View style={[styles.line]} ></View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.caption}>Beruf</Text>
                    <Text style={styles.text}>{tenant.profession}</Text>
                    <View style={[styles.line]} ></View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.caption}>Arbeitsvertrag</Text>
                    <RadioButtonGroup initIndex={tenant.contractLimitation ? 0 : 1} horizontal disabled buttons={[{label: "befristet", value: tenant.contractLimitation ? true : false}, {label: "unbefristet", value: !tenant.contractLimitation ? true : false}]}></RadioButtonGroup>
                </View>
                {tenant.contractLimitation ? 
                    <View style={styles.section}>
                        <Text style={styles.caption}>befristet bis</Text>
                        <Text style={styles.text}>{limitation}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                :
                    <View></View>
                }
                <View style={styles.section}>
                    <Text style={styles.caption}>Arbeitgeber</Text>
                    <Text style={styles.text}>{tenant.employer}</Text>
                    <View style={[styles.line]} ></View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.caption}>Standort</Text>
                    <Text style={styles.text}>{tenant.employerLocation}</Text>
                    <View style={[styles.line]} ></View>
                </View>
                <Text style={[styles.caption, {marginBottom: 10}]}>Netto Verdienst</Text>
                <View style={{flexDirection: "row", width: "100%"}}>
                    <View style={[styles.section, {width: "50%"}]}>
                        <Text style={styles.caption}>Von</Text>
                        <Text style={styles.text}>{tenant.salaryMin}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                    <View style={[styles.section, {width: "50%"}]}>
                        <Text style={styles.caption}>Bis</Text>
                        <Text style={styles.text}>{tenant.salaryMax}</Text>
                        <View style={[styles.line]} ></View>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
        height: "100%",        
    },
    pictures: {
        width: "100%",
        height: 200
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption:{
        fontSize: 10,
        color: "#A2A8B6"
    },
    text:{
        marginTop: 5,
        marginBottom: 5
    },
    section:{
        marginBottom: 20
    }
   

})



export default TenantProfessionTab;