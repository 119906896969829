import React from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { GridButton, Button, Header, ButtonVariants, Icon } from '../../components/base';

import Delivery from "../../assets/svg/Delivery.svg";
import { AuthService, StorageService, ProfileService } from '../../services';
import { Navigatable } from '../../types/componentInterfaces';
import { UserType } from '../../types/dataStructures';
import { UtilComponent } from '../../types/UtilComponent';





export interface NavigationScreenProps extends Navigatable {

}

class NavigationScreen extends UtilComponent<NavigationScreenProps> {

    state = {user: this.user, searches: 3, matches: 2, viewings: 1, rentalRequests: 2, objectCount: 0}

    auth = new AuthService();

    storage = new StorageService()

    get isTenant(): boolean{
        return this.user.userType == UserType.TENANT;
    }

    constructor(props: NavigationScreenProps) {
        super(props);
    }

    async logout(){
        try {
            let res = this.auth.logout()
            if(res){
                this.storage.removeItem("status");
                this.auth.authCallback ? await this.auth.authCallback() : console.log("auth callback not defiend")
                this.props.navigation.reset({index: 0, routes: [{name: "Login", params:{}}]})
            }
        } catch (error) {
            console.log(error)
        }
    }

    back() {
        console.log(this.props.navigation.canGoBack())
        this.props.navigation.goBack()
    }
    
    toApartments(){
        this.props.navigation.navigate("ApartmentOverview")
    }

    async componentDidMount(){
        try {
            let data = await this.updateObjectList();
            this.setState({objectCount: data.length})
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <View style={styles.screen}>
                <Header onMenu={()=>{this.props.navigation.navigate("ProfileCompletion")}}></Header>
                <View style={styles.line} ></View>
                <ScrollView>
                    <View style={styles.content}>
                        <Text style={styles.caption} >Willkommen 🤝</Text>
                        <Text style={styles.sub}>Hallo {this.state.user.firstname}!</Text>
                        <Text style={styles.sub}>Wir wünschen dir viel Erfolg!</Text>

                        <View style={styles.row}>
                            <GridButton onPress={()=>{this.props.navigation.navigate("ProfileBase")}} title={this.isTenant ? "Dein Profil" : "Ihr Profil"} subTitle="Anpassen">
                                <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                            </GridButton>
                            {this.isTenant ? 
                                <GridButton title="Deine Suche" subTitle={this.state.searches + " gespeicherte Suchen"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            :
                                <GridButton onPress={()=>{this.toApartments()}} title="Ihre Wohnung" subTitle={this.state.objectCount + " Wohnungen inseriert"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            }
                        </View>

                        <View style={styles.row}>
                            {this.isTenant ? 
                                <GridButton title="Angebote" subTitle={this.state.matches + " Wohnungen, die zu dir passsen"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            :
                                <GridButton title="Interessenten" subTitle={this.state.matches + " Mieter, die zu dir passsen"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            }
                            {this.isTenant ? 
                                <GridButton title="Besichtigungen" subTitle={this.state.viewings + " Wohnungen besichtigt"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            :
                                <GridButton onPress={()=>{this.props.navigation.navigate("ViewingRequests")}} title="Besichtigungs- anfragen" subTitle={this.state.viewings + " Anfragen"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            }
                        </View>

                        <View style={styles.row}>
                            {this.isTenant ? 
                                <GridButton title="Beruf und Finanzen" subTitle={"Gehalt, Schufa, Vertrag"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            :
                                <GridButton title="Mietanfragen" subTitle={this.state.rentalRequests + " Anfragen"}>
                                    <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                                </GridButton>
                            }
                            <GridButton title="Einstellungen" subTitle="Passwort, Benachrichtigungen etc.">
                                <Delivery height={40} width={40} style={{alignSelf:"center"}}></Delivery>
                            </GridButton>
                        </View>

                        <View style={styles.bottom}>
                            <Button title="Abmelden" onPress={()=>{this.logout()}} variant={ButtonVariants.primary} prefix={<Icon size={25} color={'#ffffff'} name="close-circle-outline" ></Icon>}></Button>
                        </View>

                    </View>
                </ScrollView>
                


            </View>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        padding: 25,
        
    },
    content: {
        padding: 25,
    },
    menu:{
        alignSelf: "flex-end"
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        margin: 2
    },
    screen:{
        backgroundColor: "#ffffff",
        height: "100%",
        
    },
    caption:{
        fontSize: 40,
        marginBottom: 15
    },
    sub:{
        fontSize: 24
    },
    row:{
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        marginTop: 20

    },
    bottom:{
        marginTop: 30,
        marginBottom: 30
    }
    
})



export default NavigationScreen;