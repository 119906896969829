import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ArrowButton, Button, ButtonVariants, Header, Textfield } from '../../components/base';
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';

import { isEmail, isEmpty } from "validator";
import { KeyboardType } from '../../types/propValueEnums';
import { RegisterFlows } from '../../types/componentInterfaces/RegisterFlow';
import { UtilComponent } from '../../types/UtilComponent';



export interface EmailScreenProps extends Navigatable, Route<RegisterFlow> {

}

class EmailScreen extends UtilComponent<EmailScreenProps> {

    state = {user: this.user, error: ""}

    constructor(props: EmailScreenProps) {
        super(props);
    }

    next(){
        this.props.navigation.navigate("registerPassword", {registerFlow: this.props.route.params.registerFlow })
    }
    
    prev(){
        this.props.navigation.goBack();
    }

    setEmail(text: string){
        this.setState({email: text});
        this.user.email = text;
        this.checkInput()
    }

    checkInput(){
        if(isEmpty(this.user.email) || !isEmail(this.user.email))
            this.setState({error: "Bitte gebe eine gültige E-Mail-Adresse ein."});
        else
            this.setState({error: undefined});
        }

    getCaption(){
        let caption
        switch (this.props.route.params.registerFlow) {
            case RegisterFlows.HOUSING_COMPANY:
                caption = <Text testID="caption" style={styles.caption}>Wie lautet Ihre E-Mail-Adresse?</Text>
            break;
            case RegisterFlows.LANDLORD:
                caption = <Text testID="caption" style={styles.caption}>Wie lautet Ihre E-Mail-Adresse?</Text>
            break;
            case RegisterFlows.TENANT:
                caption = <Text testID="caption" style={styles.caption}>Wie ist deine E-Mail-Adresse?</Text>
            break;
        
            default:
                break;
        }
        return caption;
    }

    register(){
        try {
            let res = this.profileService.register();
            if(res){
                this.props.navigation.reset({index: 0, routes: [{name: "registerFinish", params: {registerFlow: this.props.route.params.registerFlow }}]})
            }
            
        } catch (error) {
            
        }
    }

    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    
                    {this.getCaption()}
                    
                    <Textfield testID="email" error={this.state.error} keyboardType={KeyboardType.emailAddress} value={this.state.user.email} onChange={(text)=>{this.setEmail(text)}} title="E-Mail-Adresse" ></Textfield>
                    
                    <View style={styles.arrows}>
                        <View style={styles.prev}>
                            <ArrowButton back onPress={()=>{this.prev()}} ></ArrowButton>
                        </View>
                        {/* <View style={styles.next}>
                            <ArrowButton testID="next" disabled={this.state.error != undefined} onPress={()=>{this.next()}} ></ArrowButton>
                        </View> */}
                    </View>
                    <Button testID="register" disabled={this.state.error != undefined} title="Registrieren" variant={ButtonVariants.primary} onPress={()=>{this.register()}}></Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    arrows:{
        flexDirection: "row",
        marginTop: 100
    },
    next:{
        marginLeft: "auto"
    },
    prev:{
    },


})



export default EmailScreen;