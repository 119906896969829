import React, { Component } from 'react';
import { Text } from 'react-native';
import { View, StyleSheet } from 'react-native';
import { ElevatedButton, Textfield } from '../../../components/base';

import Wohnung from '../../../assets/svg/Wohnung.svg';
import Haus from '../../../assets/svg/Haus.svg';


import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { RentObjectType } from '../../../types/dataStructures';
import { KeyboardType } from '../../../types/propValueEnums';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';

export interface ApartmentNewTypeScreenProps extends Navigatable {

}

class ApartmentNewTypeScreen extends ApartmentUtilComponent<ApartmentNewTypeScreenProps> {

    state = {object: this.object }

    from;

    to;

    constructor(props: ApartmentNewTypeScreenProps) {
        super(props);
        this.from = React.createRef()
        this.to = React.createRef()
    }

    render() {
        return (
            <View style={styles.screen}>
                <KeyboardAwareScrollView>
                    <View>
                        <View style={styles.content}>
                            <Text style={styles.caption}>Was möchtest du vermieten?</Text>
                            <View style={{ flexDirection: "row", justifyContent: "space-between", marginRight: 20, marginLeft: 20, marginTop: 20 }}>
                                <ElevatedButton onPress={() => { this.setState({ object: {type: RentObjectType.apartment} }) }} isSelected={this.state.object.type == RentObjectType.apartment}>
                                    <Wohnung ></Wohnung>
                                    <Text>Wohnung</Text>
                                </ElevatedButton>

                                <ElevatedButton onPress={() => { this.setState({ object: {type: RentObjectType.house} }) }} isSelected={this.state.object.type == RentObjectType.house}>
                                    <Haus ></Haus>
                                    <Text>Haus</Text>
                                </ElevatedButton>
                            </View>
                            <Text style={styles.caption}>Baujahr</Text>
                            <Textfield nextRef={this.from} keyboardType={KeyboardType.decimalPad} returnKeyType="next" value={this.state.object.constructionYear ? this.state.object.constructionYear + "" : ""}  onChange={(text) => { this.setState({ object: {constructionYear: text} }) }} ></Textfield>


                            <Text style={styles.caption}>Mietdauer</Text>

                            <View style={{ flexDirection: "row" }}>
                                <Textfield style={{marginRight: 40}} returnKeyType="next" nextRef={this.to} ref={this.from} value={this.state.object.rent_min ? this.state.object.rent_min + "" : ""} title="Von" keyboardType={KeyboardType.decimalPad} onChange={(text) => { this.setState({ object: {rent_min: text} }) }} ></Textfield>
                                <Textfield ref={this.to} onSubmit={()=> {this.props.navigation.navigate("SpecificType")}} value={this.state.object.rent_max ? this.state.object.rent_max + "" : ""} keyboardType={KeyboardType.decimalPad} title="Bis" onChange={(text) => { this.setState({ object: {rent_max: text} }) }} ></Textfield>
                            </View>


                        </View>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        margin: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    elevatedButton: {
        padding: 20,
        backgroundColor: 'white',
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        alignSelf: "flex-start"
    },
    caption: {
        fontSize: 25,
        marginTop: 20
    }

})



export default ApartmentNewTypeScreen;