import React from "react"
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { Textfield, Button, ButtonVariants, ItemPicker } from '../../../components/base';
import { ProfileService } from '../../../services';
import { ListData, UserType } from '../../../types/dataStructures';
import { KeyboardType } from '../../../types/propValueEnums';
import { UtilComponent } from '../../../types/UtilComponent';
import { TITLES } from "../../../types/propValueEnums/Titles";


export interface PersonalScreenProps {

}

class PersonalScreen extends UtilComponent<PersonalScreenProps> {

   state = { user: this.user, firstnameError: "", lastnameError: "", emailError: "" }


    get caption(): string{
        if(this.profileService.userType == UserType.HOUSING_COMPANY){
            return "Ansprechpartner";
        }
        else{
            return "";
        }
    }

    constructor(props: PersonalScreenProps) {
        super(props);
    }

    async save(){
        try {
            this.profileService.saveProfile();
            console.log("Success")
        } catch (error) {
            console.log(error)
        }
    }

    getTitle(name: string): ListData{
        return TITLES.find(t => t.name == name)
    }

    
    render() {
        return (
            <ScrollView>
                <View style={styles.common}>
                    <Text style={styles.housingCaption}>{this.caption}</Text>
                    <ItemPicker title="Anrede" testID="title-picker" value={this.getTitle(this.state.user.title)} items={TITLES} onChange={(itemValue)=>{this.setState({user: {title: itemValue.name}}); console.log(itemValue)}}></ItemPicker>
                    <View style={styles.underline}></View>

                    <Textfield error={this.state.firstnameError} value={this.state.user.firstname} onChange={(text)=>{this.setState({user: {firstname: text}})}} title="Vorname" ></Textfield>
                    <Textfield error={this.state.lastnameError} value={this.state.user.lastname} onChange={(text)=>{this.setState({user:{lastname: text}})}} title="Nachname" ></Textfield>
                    
                    {this.user.userType == UserType.TENANT ? 
                        <Textfield value={this.state.user.birthday} onChange={(text)=>{this.setState({birthday: text}); this.setState({user:{birthday: text}})}} title="Geburtstag" ></Textfield>
                        :
                        <></>
                    }
                    
                    {this.user.userType == UserType.TENANT ?    
                        <Textfield   onChange={(text)=>{this.setState({user:{tenantDetails:{personal_status: text}}}); }} title="Familienstand" ></Textfield>
                        :
                        <></>
                    }
                    
                    {true ? <></> :
                        <Textfield keyboardType={KeyboardType.emailAddress} error={this.state.emailError} value={this.state.user.email} onChange={(text)=>{this.setState({user:{email: text}});}} title="E-Mail-Adresse" ></Textfield>
                    }
                    
                    <Textfield keyboardType={KeyboardType.phonePad} value={this.state.user.phone_number} onChange={(text)=>{this.setState({user:{phone_number: text}});}} title="Telefonnummer" ></Textfield>

                    <View style={{margin: 25, marginBottom: 50}}>
                        <Button title="Speichern" variant={ButtonVariants.primary} onPress={()=>{this.save()}} ></Button>
                    </View>

                </View> 

            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        padding: 25,
        backgroundColor: "#ffffff",
        height: "100%"
    },
    pickerCaption:{
        fontSize: 16,
        color: "#A2A8B6",
    },
    underline:{
        width:"100%",
        height: 2,
        backgroundColor: "#C9CFDF"
    },
    housingCaption:{
        fontSize: 24,
        marginBottom: 20
    }
    
    
})



export default PersonalScreen;