import { Address } from "./Address";
import { ListData } from "./ListData";
import { Location } from "./Location";
import { User } from "./UserProfile";

export enum RentObjectType {
    apartment="apartment",
    house="house"
}

export enum ApartmentType {
    souterrain,
    erdgeschoss,
    hochpaterre,
    etage,
    loft,
    maisonette,
    terrasse,
    penthouse,
    dachgeschoss 
}

export enum HouseType {
    bauern,
    doppel,
    einfamilie,
    villa,
    reihe,
    bungalow,
    sonstige
}

export type SpecificType = HouseType | ApartmentType;



export type RentObject = {
    id?: string;
    type?: RentObjectType;
    rooms?: number;
    space?: number;
    
    kaution?: number
    provision?: number
    energy?: number

    facilities?: ListData[]

    allowedPets?: ListData[]
    arePetsAllowed?: boolean;

    address?: Location;
    pictures?: Array<string>;
    thumbnail?: string;
    matches?: number;
    tenants?: Array<any>;
    constructionYear?: string;
    specificType?: SpecificType;

    rent_cold?: number,
    rent_complete?: number,
    rent_max?: number,
    rent_min?: number,
    rent_warm?: number,

    owner?: User,

    sharing?: ListData[],

    albums?: {
        files?: 
          {
            content_type?: string,
            filename?: string,
            id?: string,
            path?: string,
            storage_type?: string
          }[],
        id?: string,
        name?: string
      }[]
}