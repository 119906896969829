import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableNativeFeedback } from 'react-native';
import { Disable, Pressable } from '../../types/componentInterfaces';


export interface DotProps extends  Disable, Pressable {
    amount: number,
    initIndex?:number
    
}

class DotNavigation extends Component<DotProps> {

    state={index: 0}

    constructor(props: DotProps) {
        super(props);
        if(props.initIndex){
            this.state.index = props.initIndex;
        }
    }

    setIndex(i: number){
        this.setState({index: i})
        if(this.props.onPress){
            this.props.onPress(i);
        }
    }

    renderDots(){
        let dots = [];

        for(let i = 0 ; i < this.props.amount ; i++){
            dots.push(
                <TouchableNativeFeedback key={i} style={dotStyles.round} onPress={()=>this.setIndex(i)}>
                    <View style={[dotStyles.common, dotStyles.round, this.state.index == i ? dotStyles.selected : dotStyles.notSelected]}></View> 
                </TouchableNativeFeedback>
            );
        }
        return dots;
    }

    render() {
        return (
            <View style={dotContainerStyles.common}>
                {this.renderDots()}
            </View>
        );
    }
}

const dotContainerStyles = StyleSheet.create({
    common:{
        flexDirection: "row",
        
    }
})

const dotStyles = StyleSheet.create({
    round:{
        borderRadius: 100,
    },
    common:{
        height: 15,
        width: 15,
        margin: 7
    },
    selected:{
        borderColor: "#ffffff",
        borderWidth: 1,
        
    },
    notSelected:{
        backgroundColor: "#ffffff"
    }
})



export default DotNavigation;