import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import { Route } from '../../../types/componentInterfaces/Route';


export interface TenantOtherTabProps extends Navigatable, Route<Tenant> {

}

class TenantOtherTab extends Component<TenantOtherTabProps> {

    state = { }

    constructor(props: TenantOtherTabProps) {
        super(props);
    }


    render() {
        


        return (
            <View style={styles.screen}>
                <Text>Other</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
        height: "100%",        
    },
    pictures: {
        width: "100%",
        height: 200
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
   

})



export default TenantOtherTab;