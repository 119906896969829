import { Singleton } from "singleton-decorator";


@Singleton
export class PhotoService{
    

    pickPhotos(options: any): Promise<Array<any>>{
        return new Promise((resolve, reject) => {
            reject()
        })
    }

    takePhotos(options: any): Promise<Array<any>>{
        return new Promise((resolve, reject) => {
            reject()
        })
    }

}
