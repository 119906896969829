import React from 'react';
import { ScrollView, Text } from 'react-native';
import { View, StyleSheet } from 'react-native';
import { ElevatedButton } from '../../../components/base';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { RentObjectType } from '../../../types/dataStructures';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { ApartmentType, SpecificType, HouseType } from '../../../types/dataStructures/RentObject';

const Types = { ...ApartmentType, ...HouseType};

export interface ApartmentNewSpecificTypeScreenProps extends Navigatable {

}

class ApartmentNewSpecificTypeScreen extends ApartmentUtilComponent<ApartmentNewSpecificTypeScreenProps> {

    state = { object: this.object }

    apartmentButtonItems: {label: string, type: SpecificType}[] = [
        { label: 'Souterrain', type: Types.souterrain },
        { label: 'Erdgeschoss', type: Types.erdgeschoss },
        { label: 'Hochpaterre', type: Types.hochpaterre },
        { label: 'Etagenwohnung', type: Types.etage },
        { label: 'Loft', type: Types.loft },
        { label: 'Maisonette', type: Types.maisonette },
        { label: 'Terassenwohnung', type: Types.terrasse },
        { label: 'Penthouse', type: Types.penthouse },
        { label: 'Dachgeschoss', type: Types.dachgeschoss },
    ]
    
    houseButtonItems: {label: string, type: SpecificType}[] = [
        { label: 'Bauernhaus', type: Types.bauern },
        { label: 'Doppelhaushälfte', type: Types.doppel },
        { label: 'Einfamilienhaus', type: Types.einfamilie },
        { label: 'Villa', type: Types.villa },
        { label: 'Reihenhaus', type: Types.reihe },
        { label: 'Bungalow', type: Types.bungalow },
        { label: 'Sonstige', type: Types.sonstige },
    ]

    constructor(props: ApartmentNewSpecificTypeScreenProps) {
        super(props);

    }

    render() {
        return (
            <View style={styles.screen}>
                
                <KeyboardAwareScrollView>
                    <View>
                        <View style={styles.content}>
                            <Text style={styles.caption}>Was möchtest du vermieten?</Text>
                            <ScrollView>
                                <View style={{ flexDirection: "row", flexWrap: 'wrap', justifyContent: "space-between" }}>
                                    {this.object.type == RentObjectType.apartment ? 
                                        this.apartmentButtonItems.map((item, index) => (
                                            <View key={index} style={styles.buttonWrapper}>
                                                <ElevatedButton onPress={() => { this.setState({object: {specificType: item.type}}, ()=> {this.props.navigation.navigate("Location")}) }} isSelected={this.state.object.specificType == item.type}>
                                                    <View style={styles.button}>
                                                        <Text style={styles.buttonText}>{item.label}</Text>
                                                    </View>
                                                </ElevatedButton>
                                            </View>
                                        ))
                                    :
                                        this.houseButtonItems.map((item, index) => (
                                            <View key={index} style={styles.buttonWrapper}>
                                                <ElevatedButton onPress={() => { this.setState({object: {specificType: item.type}}, ()=> {this.props.navigation.navigate("Location")}) }} isSelected={this.state.object.specificType == item.type}>
                                                    <View style={styles.button}>
                                                        <Text style={styles.buttonText}>{item.label}</Text>
                                                    </View>
                                                </ElevatedButton>
                                            </View>
                                        ))

                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    elevatedButton: {
        padding: 20,
        margin: 100,
        backgroundColor: 'white',
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        alignSelf: "flex-start"
    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    button: {
        height: 110,
        width: 100,
        margin: 3,
        backgroundColor: '#274293',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText:  {
        color: '#ffffff',
        fontSize: 13,
    },
    buttonWrapper: {
        margin: 5
    }


})



export default ApartmentNewSpecificTypeScreen;