import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { ItemPicker, Textfield } from '../../../components/base';
import { ItemTypes } from '../../../types/propValueEnums';


export interface ApartmentNewLocationScreenProps extends Navigatable {

}

class ApartmentNewLocationScreen extends ApartmentUtilComponent<ApartmentNewLocationScreenProps> {

    state = { object: this.object, streetError: "", zipcodeError: "", cityError: "" }

    numb;
    zip;

    constructor(props: ApartmentNewLocationScreenProps) {
        super(props);
        this.zip = React.createRef();
        this.numb = React.createRef();
    }

    render() {
        return (
            <View style={styles.screen}>

                <KeyboardAwareScrollView>
                    <View>
                        <View style={styles.content}>
                            <Text style={styles.caption}>Wo befindet sich das Objekt, das du vermieten möchtest?*</Text>
                            <View style={styles.row}>
                                <View style={[styles.field, {marginRight: 5}]}>
                                    <Textfield error={this.state.streetError} returnKeyType="next" nextRef={this.numb} value={this.state.object.address?.street} onChange={(text) => { this.setState({ object: { address: { ...this.state.object.address, street: text } } }) }} title="Straße" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <Textfield ref={this.numb} returnKeyType="next" nextRef={this.zip} error={this.state.streetError} value={this.state.object.address?.street_number} onChange={(text) => { this.setState({ object: { address: { ...this.state.object.address,street_number: text } } }) }} title="Hausnummer" ></Textfield>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={[styles.field, {marginRight: 5}]}>
                                    <Textfield ref={this.zip} onSubmit={()=> {this.props.navigation.navigate("Size")}} error={this.state.zipcodeError} value={this.state.object.address?.zip_code} onChange={(text) => { this.setState({ object: { address: { ...this.state.object.address,zip_code: text } } }); }} title="Postleitzahl" ></Textfield>
                                </View>
                                <View style={styles.field}>
                                    <ItemPicker value={this.state.object.address?.city} title="Stadt" onChange={(city) => { this.setState({ object: { address: { ...this.state.object.address,city } } }) }} itemType={ItemTypes.CITIES} ></ItemPicker>
                                </View>
                            </View>
                        </View>
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
    },
    field: {
        width: "50%"
    },

})



export default ApartmentNewLocationScreen;