import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { ItemPicker } from '../../../components/base';
import Button, { ButtonVariants } from '../../../components/base/Button';
import Textfield from '../../../components/base/Textfield';
import { UserType } from '../../../types/dataStructures/UserType';
import { ItemTypes } from '../../../types/propValueEnums';
import { UtilComponent } from '../../../types/UtilComponent';

export interface AddressScreenScreenProps {

}

class AddressScreen extends UtilComponent<AddressScreenScreenProps> {

    state = { user: this.user, streetError: "", zipcodeError: "", cityError: "" }


    get caption(): string {
        switch (this.user.userType) {
            case UserType.TENANT:
                return "Wo wohnst du aktuell?"
            case UserType.LANDLORD:
                return "Wie lautet Ihre Anschrift?"
            case UserType.HOUSING_COMPANY:
                return "Unternehmensanschrift"
        }
    }

    constructor(props: AddressScreenScreenProps) {
        super(props);

    }

    async save() {
        try {
            this.profileService.saveProfile();
        } catch (error) {
            console.log(error)
        }
    }

    setState(newState: any): Promise<any>{
       
        if(newState.user && newState.user.address && this.state.user.address){
            let oldAddress = this.state.user.address;
            Object.assign(oldAddress, newState.user.address);
            newState.user.address = oldAddress;
        }
        return super.setState(newState);
       
    }

    render() {
        return (
            <View style={styles.common}>
                <Text style={styles.caption}>{this.caption}</Text>
                <View style={styles.row}>
                    <View style={[styles.field, {marginRight: 5}]}>
                        <Textfield error={this.state.streetError} value={this.state.user.address?.street} onChange={(text) => { this.setState({ user: { address: { street: text } } }) }} title="Straße" ></Textfield>
                    </View>
                    <View style={styles.field}>
                        <Textfield error={this.state.streetError} value={this.state.user.address?.street_number} onChange={(text) => { this.setState({ user: { address: { street_number: text } } }) }} title="Hausnummer" ></Textfield>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={[styles.field, {marginRight: 5}]}>
                        <Textfield error={this.state.zipcodeError} value={this.state.user.address?.zip_code} onChange={(text) => { this.setState({user:{address:{zip_code: text}}}); }} title="Postleitzahl" ></Textfield>
                    </View>
                    <View style={styles.field}>
                        <ItemPicker value={this.state.user.address?.city} title="Stadt"  onChange={(city)=>{this.setState({user:{address:{city}}})}} itemType={ItemTypes.CITIES} ></ItemPicker>
                    </View>
                </View>

                <View style={{ margin: 25, marginBottom: 50 }}>
                    <Button title="Speichern" variant={ButtonVariants.primary} onPress={() => { this.save() }} ></Button>
                </View>

            </View>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        padding: 25,
        backgroundColor: "#ffffff",
        height: "100%"
    },
    row: {
        flexDirection: "row",
    },
    field: {
        width: "50%"
    },
    caption: {
        fontSize: 24,
        marginBottom: 20
    }


})



export default AddressScreen;