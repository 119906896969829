import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Modal, Pressable, Platform } from 'react-native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Navigatable } from '../../../types/componentInterfaces';
import { ApartmentUtilComponent } from '../../../types/ApartmentUtilComponent';
import { Button, ButtonVariants, ElevatedButton, Textfield, Icon } from '../../../components/base';
import { ScrollView } from 'react-native-gesture-handler';
import { ListData } from '../../../types/dataStructures';
import { ItemService, PhotoService } from '../../../services';

import { isEmpty } from "validator";
import { MenuView } from '@react-native-menu/menu';

export interface ApartmentNewPictruesScreenProps extends Navigatable {

}

class ApartmentNewPictruesScreen extends ApartmentUtilComponent<ApartmentNewPictruesScreenProps> {

    state = { object: this.object, items: new Array<ListData>(), rooms: ['Wohnzimmer', 'Küche', 'Badezimmer', 'Schalfzimmer',], modalVisible: false, newRoom: "", newRoomError: "" }

    itemService = new ItemService();

    constructor(props: ApartmentNewPictruesScreenProps) {
        super(props);

    }

    async handleImages(room: string, assets: any[]) {
        try {

            if (!this.state.object.albums) {
                this.state.object.albums = []
            }
            let albums = this.state.object.albums
            let albumIndex = albums.findIndex(a => a.name == room);

            if (albumIndex == -1){
                albumIndex = (albums.push({name: room, files: []}) - 1);
            }

            let album = albums[albumIndex];
            if (album) {
                assets.forEach(asset => {
                    let foundIndex = album.files.findIndex(f => f.filename == asset.fileName)
                    let file = {
                        content_type: asset.type,
                        filename: asset.fileName,
                        path: asset.uri,
                    };
                    if (foundIndex != -1) {
                        album.files[foundIndex] = file;
                    }
                    else {
                        album.files.push(file);
                    }
                });
                albums[albumIndex] = album;
            }

            this.setState({ object: { albums: albums } })
            console.log(assets)
        } catch (error) {
            console.log(error)
        }
    }

    async pickPhotos(room: string){
        try {
            // let assets = await this.photos.pickPhotos({mediaType: 'photo'});
            // this.handleImages(room, assets)
        } catch (error) {
            console.log(error)
        }
    }
    
    async takePhotos(room: string){
        try {
            // let assets = await this.photos.takePhotos({mediaType: 'photo', cameraType: 'back'});
            // this.handleImages(room, assets)
        } catch (error) {
            console.log(error)
        }
    }

    async addRoom() {
        if (isEmpty(this.state.newRoom)) {
            this.setState({ newRoomError: 'Pflichtfeld' })
            return
        }
        await this.setState({ rooms: [...this.state.rooms, this.state.newRoom], modalVisible: false })
    }

    async addObject(){
        try {
            console.log(this.object)
            // return 
            let obj = await this.apartmentService.addObject(this.object)
            console.log(obj)
        } catch (error) {
            console.log(error)
        }
    }
    
    async updateObject(){
        try {
            console.log(this.object)
            // return 
            // let obj = await this.apartmentService.addObject(this.object)
            this.updateObjectList();
            this.props.navigation.navigate("ApartmentDetail")
            console.log()
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <View style={styles.screen}>

                <KeyboardAwareScrollView>
                    <View>
                        <ScrollView>
                            <View style={styles.content}>
                                <Text style={styles.caption}>Füge Bilder hinzu! * </Text>

                                <View style={{ flexDirection: "row", flexWrap: 'wrap', justifyContent: "space-between" }}>
                                    {this.state.rooms.map((room, index) => (
                                        <MenuView
                                            key={index}
                                            title="Menu Title"
                                            shouldOpenOnLongPress={false}
                                            onPressAction={({ nativeEvent }) => {
                                                if (nativeEvent.event == 'lib') {
                                                    this.pickPhotos(room)
                                                }
                                                else if (nativeEvent.event == 'cam') {
                                                    this.takePhotos(room)
                                                }
                                            }}
                                            actions={[
                                                {
                                                    id: 'lib',
                                                    title: "Fotos für " + room + " auswählen",
                                                    titleColor: '#363636',
                                                    image: Platform.select({
                                                        ios: 'plus',
                                                        android: 'ic_menu_gallery',
                                                    }),
                                                    imageColor: '#363636',

                                                },
                                                {
                                                    id: 'cam',
                                                    title: "Fotos für " + room + " aufnehmen",
                                                    titleColor: '#363636',
                                                    image: Platform.select({
                                                        ios: 'camera',
                                                        android: 'ic_menu_camera',
                                                    }),
                                                    imageColor: '#363636',
                                                },
                                            ]}
                                        >
                                            <View style={styles.buttonWrapper}>
                                                <ElevatedButton onPress={() => { }} isSelected={false}>
                                                    <View style={styles.button}>
                                                        <Text style={styles.buttonText}>{room}</Text>
                                                        <Icon name="add" color="#909090" ></Icon>
                                                    </View>
                                                </ElevatedButton>
                                            </View>
                                        </MenuView>
                                    ))}
                                </View>
                                <TouchableOpacity onPress={() => { this.setState({ modalVisible: true }) }} style={{ alignItems: 'flex-end' }}>
                                    <Icon name="add" color="#909090" ></Icon>
                                </TouchableOpacity>
                                <Button title="Speichern" variant={ButtonVariants.primary} onPress={()=>{this.apartmentService.isEditFlow ? this.updateObject() : this.addObject()}}></Button>
                                
                            </View>
                        </ScrollView>
                    </View>

                </KeyboardAwareScrollView>

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    onRequestClose={() => {
                        this.setState({ modalVisible: false })
                    }}
                >
                    <Pressable onPress={(e) => { e.stopPropagation(); }} style={styles.modalWrapper} >
                        <View style={styles.modal} >
                            <Text >Zimmer hinzufügen</Text>
                            <View style={{ marginBottom: 15, marginTop: 15, height: 80 }}>
                                <Textfield onSubmit={() => { this.addRoom() }} returnKeyType={'send'} title="Name" value={this.state.newRoom} onChange={(text) => { this.setState({ newRoom: text }) }} ></Textfield>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                                <TouchableOpacity style={[styles.buttonClose, styles.modalButton]} onPress={(e) => { this.setState({ modalVisible: false }) }} >
                                    <Text style={styles.buttonTextStyle} >Schließen</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.buttonOpen, styles.modalButton]} onPress={(e) => { this.addRoom() }} >
                                    <Text style={styles.buttonTextStyle}>Speichern</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Pressable>
                </Modal>



            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        marginTop: 2
    },
    screen: {
        backgroundColor: "#ffffff",
        height: "100%",

    },
    caption: {
        fontSize: 25,
        marginTop: 20,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
    },
    field: {
        width: 50
    },
    button: {
        height: 110,
        width: 100,
        margin: 3,
        backgroundColor: '#f8f8f8',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        color: '#363636',
        fontSize: 13,
    },
    buttonWrapper: {
        margin: 5
    },
    modalWrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: 40
    },
    modal: {
        backgroundColor: '#ffffff',
        padding: 20,
        width: '100%',
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    buttonTextStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    buttonOpen: {
        backgroundColor: "#274293",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    modalButton: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },

})



export default ApartmentNewPictruesScreen;