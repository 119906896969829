import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ArrowButton, Header, ItemPicker } from '../../components/base';
import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';

import {Picker} from '@react-native-picker/picker';
import { ItemService } from '../../services';
import { RegisterFlows } from '../../types/componentInterfaces/RegisterFlow';
import { UtilComponent } from '../../types/UtilComponent';
import { TITLES } from '../../types/propValueEnums/Titles';
import { ListData } from '../../types/dataStructures';

export interface TitleScreenProps extends Navigatable, Route<RegisterFlow> {

}

class TitleScreen extends UtilComponent<TitleScreenProps> {

    state = {user: this.user}
    itemService = new ItemService();

    constructor(props: TitleScreenProps) {
        super(props);
    }
    
    async next(){
        this.props.navigation.navigate("registerName", {registerFlow: this.props.route.params.registerFlow})
    }

    getTitle(name: string): ListData{
        return TITLES.find(t => t.name == name)
    }

    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    {this.props.route.params.registerFlow == RegisterFlows.HOUSING_COMPANY ? 
                        <Text testID="contactLabel" style={styles.caption}>Ansprechpartner</Text>
                        :
                        <View/>    
                    }
                    <Text style={styles.caption}>Anrede</Text>
                    <ItemPicker testID="title-picker" value={this.getTitle(this.state.user.title)} items={TITLES} onChange={(itemValue)=>{this.setState({user: {title: itemValue.name}})}}></ItemPicker>
                    <View style={styles.underline}></View>
                    <View style={styles.next}>
                        <ArrowButton onPress={()=>{this.next()}} ></ArrowButton>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    next:{
        alignItems: "flex-end",
        marginRight: 20,
        marginTop: 100
    },
    pickerCaption:{
        fontSize: 16,
        color: "#A2A8B6",
    },
    underline:{
        width:"100%",
        height: 2,
        backgroundColor: "#C9CFDF"
    }
    
})



export default TitleScreen;