import React, { Component } from 'react';
import { StyleSheet, View, TextInput, ReturnKeyType, Text } from 'react-native';
import { Disable } from '../../types/componentInterfaces/Disable';
import { TextContentType } from '../../types/propValueEnums/TextContentType';
import { KeyboardType } from '../../types/propValueEnums/KeyboardType';
import AnimatedLine from './AnimatedLine';
import AnimatedTitle from './AnimatedTitle';
import { Focusable, Changeable, Submitable, Editable } from '../../types/componentInterfaces';


export interface TextfieldProps extends Disable, Focusable, Changeable<string>, Submitable, Editable {
    title?: string;
    keyboardType? : KeyboardType
    textContentType? : TextContentType
    multiline?: boolean;
    autoCorrect?: boolean;
    secureTextEntry?: boolean;
    value?: string;
    ref?: any;
    error?: string;
    hint?: string;
    outlined?: boolean;
    height?: number | string;
    returnKeyType?: ReturnKeyType;
    style? : any
    blurOnSubmit?: boolean
    longHint?: string
    testID?: string
    accessibilityLabel?: string
    nextRef?: any
    autofocus?: boolean;
}

class Textfield extends Component<TextfieldProps> {
    
    line;

    title;

    input;

    inputHeight: number;

    state={ value: this.props.value}

    constructor(props: TextfieldProps) {
        super(props);
        this.line = React.createRef();
        this.title = React.createRef();
        this.input = React.createRef();
    }

    

    focus(){
        this.input.current.focus();
    }
    
    onFocus(){
        this.props.onFocus ? this.props.onFocus() : undefined
        this.line.current.onFocus()
        this.title.current.onFocus()
    }
    
    onBlur(){
        this.props.onBlur ? this.props.onBlur() : undefined
        this.line.current.onBlur()
        this.title.current.onBlur()
    }

    onSubmit(){
        this.props.onSubmit ? this.props.onSubmit() : undefined;
        this.props.nextRef && this.props.nextRef.current && this.props.nextRef.current.focus ? this.props.nextRef.current.focus() : undefined;
    }

    animate(){
    }
    
    render() {
        let keyboardType = this.props.keyboardType ? this.props.keyboardType : KeyboardType.default;
        let textContentType = this.props.textContentType ? this.props.textContentType :TextContentType.none; 
        let autoCorrect = this.props.autoCorrect ? this.props.autoCorrect : true;
        let returnKeyType: ReturnKeyType = this.props.returnKeyType ? this.props.returnKeyType : 'done';

        return (
            <View style={this.props.style}>
                <AnimatedTitle hasText={(this.state.value && this.state.value != ""  && this.state.value != undefined) || (this.props.value && this.props.value != ""  && this.props.value != undefined)  ? true : false} ref={this.title} title={this.props.title} >
                    <TextInput
                        collapsable={false}
                        autoFocus={this.props.autofocus}
                        blurOnSubmit={this.props.blurOnSubmit != undefined ? this.props.blurOnSubmit : this.props.returnKeyType == "next" ? false : true}
                        ref={this.input} 
                        multiline={this.props.multiline}
                        autoCorrect={autoCorrect}
                        keyboardType={keyboardType}
                        secureTextEntry={this.props.secureTextEntry}
                        value={this.props.value}
                        editable={this.props.editable}
                        onChangeText={(text) => {this.props.onChange ? this.props.onChange(text) : undefined; this.setState({value: text})}}
                        onBlur={()=>{this.onBlur()}}
                        onFocus={()=>{this.onFocus()}}
                        onSubmitEditing={()=>{this.onSubmit()}}
                        textContentType={textContentType}
                        placeholder={this.props.hint}
                        returnKeyType={returnKeyType}
                        style={{height: this.props.height}}
                    ></TextInput>
                    <AnimatedLine isError={this.props.error != "" && this.props.error != undefined } ref={this.line}></AnimatedLine>
                </AnimatedTitle>
                {this.props.error ? 
                    <Text style={[textfieldStyles.hint ,{color: "red"}]}>{this.props.error}</Text>
                    :
                    undefined
                }
                {this.props.longHint ? 
                    <Text style={textfieldStyles.hint} >{this.props.longHint}</Text>
                    :
                    undefined
                }

            </View>

        );
    }
}

const textfieldStyles = StyleSheet.create({
    common:{
    },
    underline:{
        height: 0.5,
        //backgroundColor: "#C9CFDF",

    },
    hint:{
        color: "#70747F",
        fontSize: 11,
        
    },
    keyboardView:{
        flex: 1
    }
})



export default Textfield;