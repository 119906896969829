import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Disable, Pressable } from '../../types/componentInterfaces';


export interface ElevatedButtonProps extends Disable, Pressable {
    isSelected: boolean
}

class ElevatedButton extends Component<ElevatedButtonProps> {

    constructor(props: ElevatedButtonProps) {
        super(props);
        
    }

    render() {
        return (
            <TouchableOpacity onPress={()=>{this.props.onPress ? this.props.onPress() : undefined}}>
                <View style={[styles.elevatedButton, this.props.isSelected ? styles.selected : undefined]}>
                    {this.props.children}
                </View>
            </TouchableOpacity> 
        );
    }
}

const styles = StyleSheet.create({
    elevatedButton:{
        // padding: 20,
        backgroundColor: 'white',
        elevation: 2,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.05,
        shadowRadius: 5,
        borderWidth: 1,
        borderColor: 'transparent',
        alignSelf: "flex-start"
    },
    selected: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#274293"
    }
})


export default ElevatedButton;