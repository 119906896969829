import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { Pressable, Changeable, Disable } from '../../types/componentInterfaces';
import RadioButton from './RadioButton';


export interface RadioButtonGroupProps extends Pressable, Changeable<number>, Disable {
    buttons: Array<{label:string, value: boolean}>
    buttonColor?: string
    horizontal?: boolean
    initIndex?: number
}

class RadioButtonGroup extends Component<RadioButtonGroupProps> {

    state = {selectedIndex: this.props.initIndex ? this.props.initIndex : 0}

    constructor(props: RadioButtonGroupProps) {
        super(props);

    }

    setIndex(index: number){
        this.setState({selectedIndex: index});
        if(this.props.onChange){
            this.props.onChange(this.state.selectedIndex);
        }
    }

    render() {
        let horizontal = this.props.horizontal ? this.props.horizontal : false;
        
        return (
            <View style={horizontal ? {flexDirection: "row"} : {}}>
                {this.props.buttons.map((button, index) =>(
                    <RadioButton disabled={this.props.disabled} color={this.props.buttonColor} key={index} onPress={()=>{this.props.disabled ? undefined : this.setIndex(index)}} label={button.label} selected={this.state.selectedIndex == index} ></RadioButton>
                ))}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    

})



export default RadioButtonGroup;