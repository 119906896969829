import React, { Component } from 'react';
import { View, StyleSheet, Text, ImageBackground, FlatList, Dimensions  } from 'react-native';

import MBudeLogo from "../../assets/svg/MBude_Icon.svg";
import { Button, ButtonVariants } from '../../components/base';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { StartFlows, Navigatable } from '../../types/componentInterfaces';



const { width, height } = Dimensions.get('window');
export interface TenantIntroScreenProps extends Navigatable {

}

const image = require('../../assets/image/welcome_background.png')
class TenantIntroScreen extends Component<TenantIntroScreenProps> {


    constructor(props: TenantIntroScreenProps) {
        super(props);
    }

    toWelcome(){
        this.props.navigation.navigate("Welcome", {startFlow: StartFlows.TENANT})
    }

    
    render() {
        return (
            <ImageBackground source={image} style={styles.background} imageStyle={styles.backgroundImage}>
                <View style={styles.backgroundColor}>
                    <View style={styles.logo}>
                        <MBudeLogo height={47} width={56} ></MBudeLogo>
                        <Text style={styles.subCaption}>Meine Bude</Text>
                    </View>
                    <SwiperFlatList index={0} showPagination style={styles.swiperContainer} paginationStyle={styles.pagination}>            
                        <View style={styles.swiperSlide}>
                                <Text style={[styles.swiperText, styles.swiperCaption]}>REGISTRIEREN</Text> 
                                <Text style={styles.swiperText}>Registriere dich bei uns mit deiner E-Mail-Adresse, deinem Namen und einem Passwort, um deine Traum Wohnung zu bekommen. </Text> 
                        </View> 
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>PROFIL VERVOLLSTÄNDIGEN</Text>
                            <Text style={styles.swiperText}>Vervollständige dein Profil mit persönlichen Informationen, deinem Beruf, Familienstand usw., um den Vermieter von dir zu überzeugen.</Text>
                        </View> 
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>SUCHE</Text>
                            <Text style={styles.swiperText}>Teile uns mit, wonach du suchst und wir Sorgen dafür, dass du passende Wohnungsangebote bekommst. </Text>
                        </View> 
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>WOHNUNGSANGEBOTE</Text>
                            <Text style={styles.swiperText}>Du bekommst passende Wohnungen vorgeschlagen und entscheidest selbst, welche Wohnungen du besichtigen möchtest. </Text>
                        </View> 
                        <View style={styles.swiperSlide}>
                            <Text style={[styles.swiperText, styles.swiperCaption]}>BESICHTIGEN UND MIETEN</Text>
                            <Text style={styles.swiperText}>Vermieter werden über die App kontaktiert, so kannst du direkt einen Besichtigungstermin vereinbaren. </Text>
                        </View>  
                    </SwiperFlatList>
                    <View style={styles.buttons}>
                        <View style={{marginTop: 20}}>
                            <Button onPress={()=>{this.toWelcome()}} title="Jetzt loslegen" variant={ButtonVariants.opaque} ></Button>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        );
    }
}


const styles = StyleSheet.create({
    backgroundColor:{
        backgroundColor: '#274293D1',
        justifyContent: "center",
        height: "100%",
    },
    common:{
        padding: 25,
        justifyContent: "center"

    },
    caption:{
        fontSize: 45,
        textAlign: "center",
        color: "#ffffff"
    },
    logo:{
        marginTop: 70,
        alignItems: "center",
        
    },
    subCaption:{
        margin: 17,
        color: '#ffffff'
    },
    slogan:{
        color: '#ffffff'
    },
    buttons: {
        padding: 25,
        marginTop: "auto"
    },
    backgroundImage:{
        position: 'absolute',
        top: 0,
        bottom:"45%",
        resizeMode: "cover",
        
        
    },
    background:{
        width: "100%",
        height: "100%",
        flex: 1,
        position: "relative"
    },
    swiperText:{
        color: '#ffffff',
         textAlign: "center",
         width:width*0.8,
    },
    swiperCaption:{
        fontSize: 22,
        marginBottom: 50
    },
    swiperSlide:{
        width,
        alignItems: 'center',
        height: height*0.5,
    },
    swiperContainer:{
        flex:1,
        height:height*0.5,
        marginTop:height*0.1,
        width:width,
    },
    pagination:{
        marginBottom:height*0.2,
    }
})



export default TenantIntroScreen;