
import React, { Component } from 'react';
import { Image, Text, TouchableOpacity } from 'react-native';
import { View, StyleSheet, ScrollView, RefreshControl,  } from 'react-native';
import { Header } from '../../components/base';
import { Icon } from '../../components/base/index';
import { ApartmentService } from '../../services';
import { ApartmentUtilComponent } from '../../types/ApartmentUtilComponent';
import { Navigatable } from '../../types/componentInterfaces';
import { RentObject } from '../../types/dataStructures';
import { UtilComponent } from '../../types/UtilComponent';



export interface ApartmentOverviewScreenProps extends Navigatable {

}

class ApartmentOverviewScreen extends ApartmentUtilComponent<ApartmentOverviewScreenProps> {

    apartments: Array<RentObject> = new Array();

    state = {refreshing: false, apartments: this.objectList}

    apartmentService = new ApartmentService();

    constructor(props: ApartmentOverviewScreenProps) {
        super(props);

    }

    toApartment(index: number){
        this.props.navigation.navigate("ApartmentDetail", index)
    }

    getApartments(){
        if(this.state.apartments && this.state.apartments.length > 0){
            return this.state.apartments.map((apart, index) =>(
                <TouchableOpacity onPress={()=>{this.toApartment(index)}} key={index}> 
                    <View style={styles.listElement} key={index}>
                        <View style={styles.thumbnail}>
                            {apart.thumbnail ? 
                                <Image style={styles.picture} source={{uri: apart.thumbnail}} ></Image>
                            :
                                <></>
                            }
                        </View>
                        <View style={styles.text}>
                            <Text style={{marginBottom: 10}}>{apart.address.city.name}</Text>
                            <Text style={{marginBottom: 15}} >{apart.rooms} Zimmer | {apart.space} m² | {apart.rent_cold}€ Kalt</Text>
                            <Text style={styles.bold} >{apart.matches ? apart.matches : 0} passende Profile</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            ))
        }
        else {
            return (<View></View>)
        }

    }
    
    async onRefresh(){
        this.setState({refreshing: true})
        try {
            let apartments = await this.updateObjectList();
            this.setState({apartments})
        } catch (error) {
            console.log(error)
        } finally {
            this.setState({refreshing: false})

        }
    }

    render() {
        return (
            <View style={styles.screen}>
                <Header onBack={this.props.navigation.canGoBack ? this.props.navigation.goBack : undefined } title="Wohnungsangebote" onMenu={()=>{console.log("ARRRGH")}}></Header>
                <View style={styles.line} ></View>
                <View style={styles.content}>
                    <View style={{flexDirection: "row"}}>
                        <Text style={styles.caption} >IHRE INSERATE</Text>
                        <TouchableOpacity onPress={()=>{this.apartmentService.isEditFlow = false; this.object = {}; this.props.navigation.navigate("NewApartment")}}>
                            <Icon style={{padding:5}} color={"#4892F1"} name="add"></Icon>
                        </TouchableOpacity>
                    </View>
                    <ScrollView refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={()=>{this.onRefresh()}}/>} >
                            {this.getApartments()}
                    </ScrollView>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        margin: 2
    },
    screen:{
        backgroundColor: "#ffffff",
        height: "100%",
        
    },
    bold:{
        fontWeight: "bold"
    },
    caption:{
        fontSize: 30,
        marginBottom: 20,
        marginRight: "auto"
    },
    listElement: {
        borderColor: "#27429372",
        borderWidth: 1,
        borderStyle: 'solid',
        marginBottom: 13,
        flexDirection: "row"
    },
    thumbnail:{
        width: "30%",
        height: 100
    },
    picture:{
        width: "100%",
        height: "100%"
    },
    text:{
        padding: 8
    }
    
})



export default ApartmentOverviewScreen;