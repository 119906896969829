import React from 'react';
import { Picker, } from '@react-native-picker/picker';
import { View, Text } from 'react-native';
import { Changeable, Disable, Testable } from '../../types/componentInterfaces';
import { ListData } from '../../types/dataStructures';
import { ItemTypes } from '../../types/propValueEnums';
import { UtilComponent } from '../../types/UtilComponent';
import { ItemService } from '../../services';


export interface ItemPickerProps extends Disable, Testable, Changeable<ListData>, Testable {
    /**
     * if present, this array will be used no matter what
     */
    items?: ListData[]

    /**
     * picker will get items by itself, only if items array is not set
     */
    itemType?: ItemTypes

    /**
     * init value
     */
    value?: ListData 

    /**
     * Label text above
     */
    title?: string;
}

class ItemPicker extends UtilComponent<ItemPickerProps> {

    state= { value: this.props.value, items: [] }

    itemService = new ItemService();

    constructor(props: ItemPickerProps) {
        super(props);
    }

    onChange(val: number){
        this.setState({value: this.state.items[val]});
        this.props.onChange ? this.props.onChange(this.state.items[val]) : undefined;
    }

    async componentDidMount(){
        if(this.props.items){
            this.setState({items: this.props.items});
        }
        else if(this.props.itemType){
            try {
                let items = await this.itemService.getItems(this.props.itemType)
                this.setState({items})
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        return (
            <View>
                <Text style={{fontSize: 10, color: "#70747F"}} >{this.props.title}</Text>
                <Picker mode="dropdown" itemStyle={{padding: 0, margin: 0}} selectedValue={this.state.value?.id} onValueChange={(newVal, index)=>{this.onChange(index)}}>
                    {this.state.items.map((item, index) =>(
                        <Picker.Item key={index} style={{fontSize: 14, paddingLeft: 0}} value={item.id} label={item.name} ></Picker.Item>
                    ))}
                </Picker>
            </View>
        );
    }
}


export default ItemPicker;