import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Pressable, Disable } from '../../types/componentInterfaces';


export interface RadioButtonProps extends Pressable, Disable {
    label: string
    selected: boolean
    color?: string
}

class RadioButton extends Component<RadioButtonProps> {

    constructor(props: RadioButtonProps) {
        super(props);

    }

    render() {
        let color = ""
        if(!this.props.color){
            color = "#274293"
        }
        else{
            color = this.props.color;
        }
        return (
            <View style={styles.radioButtonContainer}>
                <TouchableOpacity disabled={this.props.disabled} onPress={()=>{this.props.onPress()}} style={[styles.radioButton, {borderColor: color}]}>
                    {this.props.selected ? <View style={[styles.radioButtonIcon, {backgroundColor: color}]} /> : null}
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{this.props.onPress()}}>
                    <Text style={styles.radioButtonText}>{this.props.label}</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    radioButtonContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        padding: 10
      },
      radioButton: {
        height: 20,
        width: 20,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: "#E6E6E6",
        alignItems: "center",
        justifyContent: "center"
      },
      radioButtonIcon: {
        height: 14,
        width: 14,
        borderRadius: 7,
        backgroundColor: "#98CFB6"
      },
      radioButtonText: {
        fontSize: 16,
        marginLeft: 16
      },

})



export default RadioButton;