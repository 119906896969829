import React, { Component} from 'react';
import { View, StyleSheet, Text, ScrollView } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ButtonVariants, CircleProgress, Header, Button } from '../../../components/base';
import { Navigatable } from '../../../types/componentInterfaces';

export interface ProfileCompletionScreenProps extends Navigatable {

}

class ProfileCompletionScreen extends Component<ProfileCompletionScreenProps> {

    state = {percent: 10}

    constructor(props: ProfileCompletionScreenProps) {
        super(props);
    }

    back() {
        
        this.props.navigation.goBack()
    }

    render() {
        return (
            <View style={styles.common}>
                <Header onBack={()=>{this.back()}} ></Header>
                <View style={styles.line} ></View>
                <ScrollView>
                    <View style={styles.content}>
                        <Text style={{fontSize: 33}}>Erzähl uns mehr Über dich  ✍🏽</Text>
                        <Text style={{marginTop: 20}} >Erzähl uns mehr über dich, um deine Chance bei der Wohnungsfindung zu steigern!</Text>
                        <Text style={{marginTop: 20}} >Unterstütze deinen zukünftiger Vermieter, indem du ihm alle wichtigeren Information bereitstellst.</Text>

                        <View style={{alignItems: "center", marginTop: 20, marginBottom: 20}}>
                            <CircleProgress scaleWidth={2.5} radius={70} bgcolor="#D8D8D8" borderWidth={10} percent={this.state.percent} innerColor="#ffffff" color="#274293" disabled={false}>
                                <Text style={{fontSize:20, fontWeight: "bold"}}>{this.state.percent}%</Text>
                                <Text style={{fontSize: 10, color: "#AAA7A6", marginBottom: 10}}>Profil</Text>
                                <TouchableOpacity onPress={()=>{console.log("to Profile")}} >
                                    <Text style={{fontSize: 12, color: "#274293"}}>Weiterbearbeiten</Text>
                                </TouchableOpacity>
                            </CircleProgress>
                        </View>

                        <View style={{marginBottom: 15}}>
                            <Button title="Jetzt dein Profil vervollständigen" variant={ButtonVariants.primary} onPress={()=>{this.setState({percent: this.state.percent + 2})}}></Button>
                        </View>
                        <Button title="Überspringen" variant={ButtonVariants.opaqueBlue} onPress={()=>{this.setState({percent: 0})}}></Button>
                    </View>
                </ScrollView>

            </View>
        );
    }
}

const styles = StyleSheet.create({
    common: {
        height: "100%",
        width: "100%",
        backgroundColor: '#ffffff'
    },
    line:{
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
        margin: 2
    },
    content:{
        padding: 25
    },
    
    
})



export default ProfileCompletionScreen;