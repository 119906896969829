import { DateTime } from 'luxon';
import React, { Component } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import Button, { ButtonVariants } from '../../../components/base/Button';
import Header from '../../../components/base/Header';
import { Navigatable } from '../../../types/componentInterfaces/Navigatable';
import { Route } from '../../../types/componentInterfaces/Route';
import { RentObject } from '../../../types/dataStructures/RentObject';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import TenantPersonalTab from './TenantPersonal';
import TenantProfessionTab from './TenantProfession';
import TenantInterestsTab from './TenantInterests';
import TenantOtherTab from './TenantOther';
import { ScrollView } from 'react-native-gesture-handler';


const Tab = createMaterialTopTabNavigator();


export interface TenantDetailScreenProps extends Navigatable, Route<{tenant: Tenant} & {apartment: RentObject}> {

}

class TenantDetailScreen extends Component<TenantDetailScreenProps> {

    state = { }

    constructor(props: TenantDetailScreenProps) {
        super(props);
    }

    render() {
        let tenant = this.props.route.params.tenant;
        let apartment = this.props.route.params.apartment

        let date: DateTime = DateTime.fromISO(tenant.birthday);
        let age = Math.floor(DateTime.local().diff(date, "years").as("years"));


        return (
            <ScrollView>
                <View style={styles.screen}>
                    <Header onBack={this.props.navigation.canGoBack ? this.props.navigation.goBack : undefined} title="Profil" ></Header>
                    <View style={styles.line} ></View>
                    <Image style={styles.pictures} source={{uri: apartment.thumbnail}} ></Image>
                    <View style={styles.content}>
                        <Text style={{fontSize: 24, marginBottom: 10}}>{this.props.route.params.tenant.firstname} {this.props.route.params.tenant.lastname}, {age}</Text>
                        <Text>{tenant.infoText}</Text>
                    </View>
                        <Tab.Navigator  backBehavior={"none"}>
                            <Tab.Screen initialParams={tenant} name="Persönlich" component={TenantPersonalTab} />
                            <Tab.Screen initialParams={tenant} name="Beruf / Finanzen" component={TenantProfessionTab} />
                            <Tab.Screen initialParams={tenant} name="Interessen" component={TenantInterestsTab} />
                            <Tab.Screen initialParams={tenant} name="Sonstiges" component={TenantOtherTab} />
                        </Tab.Navigator>
                    <View style={styles.content}>
                        <Button title="Zur Besichtigung einladen" variant={ButtonVariants.primary} ></Button>
                    </View>
                </View>

            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        padding: 25,
       
    },
    pictures: {
        width: "100%",
        height: 200
    },
    line: {
        backgroundColor: '#C9CFDF',
        width: "100%",
        height: 1,
    },
    screen: {
        backgroundColor: "#ffffff",
        width: "100%"

    },
   

})



export default TenantDetailScreen;