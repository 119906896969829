import React, { Component } from 'react';
import { View, StyleSheet, Text, ImageBackground } from 'react-native';


import MBudeLogo from "../../assets/svg/MBude_Icon.svg";
import { Button, ButtonVariants, Icon } from '../../components/base';
import { Navigatable } from '../../types/componentInterfaces';



export interface WelcomeFlowScreenProps extends Navigatable {

}

const image = require('../../assets/image/welcome_background.png')
class WelcomeFlowScreen extends Component<WelcomeFlowScreenProps> {


    constructor(props: WelcomeFlowScreenProps) {
        super(props);
    }

    toTenantIntro(){
        this.props.navigation.navigate("TenantIntro");
    }
    
    toLandlordIntro(){
        this.props.navigation.navigate("LandlordIntro");
    }


    render() {
        return (
            <ImageBackground source={image} style={styles.background} imageStyle={styles.backgroundImage}>
                <View style={styles.common}>
                    <Text style={styles.caption} >Willkommen</Text>
                    <View style={styles.logo}>
                        <MBudeLogo height={47} width={56} ></MBudeLogo>
                        <Text style={styles.subCaption}>Deine Bude</Text>
                        <Text style={styles.slogan}>HIER FINDEST ODER VERGIBST DU</Text>
                        <Text style={styles.slogan}>DEINE WOHNUNG SCHNELL UND EINFACH</Text>
                        {/* <Text style={styles.slogan}>HIER SUCHST DU NICHT</Text>
                        <Text style={styles.slogan}>HIER FINDEST DU DEINE TRAUM-WOHNUNG</Text> */}
                    </View>

                    <View style={styles.buttons}>
                        <Button onPress={()=>{this.toTenantIntro()}} title="Mieter" variant={ButtonVariants.white} ></Button>
                        <View style={{marginTop: 20}}>
                            <Button onPress={()=>{this.toLandlordIntro()}} title="Vermieter" variant={ButtonVariants.opaque} ></Button>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        padding: 25,
        backgroundColor: '#274293D1',
        height: "100%",
        justifyContent: "center"

    },
    caption:{
        fontSize: 45,
        textAlign: "center",
        color: "#ffffff"
    },
    logo:{
        marginTop: 70,
        alignItems: "center",
        
    },
    subCaption:{
        margin: 17,
        color: '#ffffff'
    },
    slogan:{
        color: '#ffffff'
    },
    buttons: {
        padding: 25,
        marginTop: "auto"
    },
    backgroundImage:{
        position: 'absolute',
        top: 0,
        bottom:"45%",
        resizeMode: "cover",
        
        
    },
    background:{
        width: "100%",
        height: "100%",
        flex: 1,
        position: "relative"
    }
    
})



export default WelcomeFlowScreen;