import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ArrowButton, Header, ItemPicker, Textfield } from '../../components/base';

import { Navigatable, RegisterFlow, Route } from '../../types/componentInterfaces';

import { isEmpty } from "validator"
import { ItemTypes } from '../../types/propValueEnums';
import { UtilComponent } from '../../types/UtilComponent';

export interface CompanyScreenProps extends Navigatable, Route<RegisterFlow> {

}

class CompanyScreen extends UtilComponent<CompanyScreenProps> {

    state = {user: this.user, nameError:"", formError: ""}

    constructor(props: CompanyScreenProps) {
        super(props);
    }

    next(){
        let error;
        
        if( !this.user.company || !this.user.company.name || isEmpty(this.user.company.name)){
            if(!error){
                error= {};
            }
            Object.assign(error, {nameError: "Pflichtfeld"})
        }
        if( !this.user.company || !this.user.company.form ){
            if(!error){
                error= {};
            }
            Object.assign(error, {formError: "Pflichtfeld"})
        }

        if(!error){
            this.props.navigation.navigate("registerTitle", {registerFlow: this.props.route.params.registerFlow })
        } else{
            this.setState(error)
        }
    }
    
    prev(){
        this.props.navigation.goBack();
    }


    render() {
        return (
            <View style={styles.common}>
                <View>
                    <Header title="Registrierung" onBack={()=>{this.props.navigation.goBack()}}></Header>
                </View>
                <View style={styles.content}>
                    
                    <Text style={styles.caption} >Unternehmensdaten</Text>
                    
                    <Textfield error={this.state.nameError} value={this.state.user.company?.name} onChange={(text)=>{this.setState({user:{company:{name: text}}})}} title="Unternehmensname" ></Textfield>
                    
                    <ItemPicker itemType={ItemTypes.CORPORATE_FORMS} value={this.state.user.company?.form} onChange={(val)=>{this.setState({user:{company:{form: val}}})}} ></ItemPicker>
                    
                    <View style={styles.arrows}>
                        <View style={styles.prev}>
                            <ArrowButton back onPress={()=>{this.prev()}} ></ArrowButton>
                        </View>
                        <View style={styles.next}>
                            <ArrowButton onPress={()=>{this.next()}} ></ArrowButton>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    common:{
        flex: 1,
        height: "100%"
    },
    content:{
        flex: 1,
        // alignItems: "center",
        justifyContent: "center",
        padding: 45
    },
    caption: {
        fontSize: 30,
        marginBottom: 60
    },
    arrows:{
        flexDirection: "row",
        marginTop: 100
    },
    next:{
        marginLeft: "auto"
    },
    prev:{
    },
    
})



export default CompanyScreen;